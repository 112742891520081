<template>
  <div class="dui-page">
    <div class="header-bar">
      <div class="flex justify-between align-center">
        <a class="logo margin-left"></a>
      </div>
    </div>
    <div class="container">
      <div class="title padding-left-xl">忘记密码</div>
      <input type="password" autocomplete="new-password" hidden>
      <div class="padding-lr-sm margin-top-xl">
        <div class="item">
          <div class="label">邮箱</div>
          <div class="edit flex align-center">
            <input class="flex-sub" v-model="form.email" type="email" :placeholder="$t('placeholder.inputYour', {name: $t('email')})">
            <button class="dui-button" @click="onSend" :disabled="countdown > 0">{{ countdown > 0 ? `${countdown}s` : '发送验证码'}}</button>
          </div>
        </div>
        <div class="item">
          <div class="label">邮箱验证码</div>
          <div class="edit flex align-center">
            <input v-model="form.code" type="text" placeholder="请输入您收到的验证码">
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('password')}}</div>
          <div class="edit flex align-center">
            <input v-model="form.password" type="password" :placeholder="$t('placeholder.inputYour', {name: $t('password')})">
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('confirmPassword')}}</div>
          <div class="edit flex align-center">
            <input v-model="form.confirmPassword" type="password" :placeholder="$t('placeholder.inputConfirmPassword')" maxlength="20">
          </div>
        </div>
      </div>
      <div class="padding-lr-sm footer">
        <button class="dui-button bg-blue block lg" @click="onLogin">确定重置</button>
        <div class="margin-tb text-center">
          <a @click="$router.back()">
            返回登录
            <icon class="margin-left-xxs" type="right"></icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validator from '@moohng/dan/src/validator'
import { emailRule, passwordRule, confirmPasswordRule, codeRule } from './rule'

export default {
  data () {
    return {
      countdown: 0,
      form: {
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
      },
    }
  },
  computed: {
    placeholderSecret () {
      return this.$t('placeholder.input', { name: this.$t('twoStepCode') })
    },
  },
  methods: {
    startCountdown () {
      clearInterval(this.timer)
      this.countdown = 60
      this.timer = setInterval(() => {
        this.countdown -= 1
        if (this.countdown === 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    onSend () {
      const result = validator(this.form, {
        email: emailRule,
      })
      if (result.hasError()) {
        return this.$toast(result.first())
      }
      const { email } = this.form
      this.$api.sendEmailCode({ email, action: 2 }).then(() => {
        this.$toast('已发送')
        this.startCountdown()
      })
    },
    onLogin () {
      const result = validator(this.form, {
        email: emailRule,
        code: codeRule,
        password: passwordRule,
        confirmPassword: confirmPasswordRule,
      })
      if (result.hasError()) {
        return this.$toast(result.first())
      }
      this.$api.resetPassword(this.form).then(() => {
        this.$toast('密码已重置')
        this.$router.back()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './login.scss';
.edit {
  .dui-button {
    margin-left: 12px;
    margin-right: -12px;
    color: var(--dui-FG);
    background-color: var(--button-bg);
  }
}
</style>
