import { REG_EMAIL } from '@/commons/const'

export const usernameRule = {
  required: true,
  alias: '账户名',
  pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
  message: '账户名由英文字母、数字和下划线(_)组成，且由英文字母开头',
  validate: (value) => {
    if (value.length < 5) {
      return '账户名至少为 5 个字符'
    }
    return true
  },
}

export const passwordRule = {
  required: true,
  alias: '密码',
  length: [6],
  message: '密码至少为6个字符',
}

export const confirmPasswordRule = {
  required: '请再次确认您的密码',
  validate: (value, { password }) => {
    if (value !== password) {
      return '两次密码输入不一致'
    }
    return true
  },
}

export const codeRule = '验证码'

export const emailRule = {
  required: true,
  alias: '邮箱',
  pattern: REG_EMAIL,
  message: '邮箱格式不正确',
}
