import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken, getTheme } from '@/commons/utils'
import Home from '@/pages/Home/Index'
import TabBar from '@/pages/commons/TabBar'
import ModuleSwap from '@/pages/Home/ModuleSwap'
import ModulePool from '@/pages/Home/ModulePool'
import ModuleDeal from '@/pages/Home/ModuleDeal'
import ModuleAirdrop from '@/pages/Home/ModuleAirdrop'
import AddLiquidity from '@/pages/Home/AddLiquidity'
import DecLiquidity from '@/pages/Home/DecLiquidity'
import MyAsset from '@/pages/Home/MyAsset'
import AssetDetail from '@/pages/Home/AssetDetail'
import Recharge from '@/pages/Home/Recharge'
import Withdraw from '@/pages/Home/Withdraw'
import AirdropRecord from '@/pages/Home/AirdropRecord'

import Login from '@/pages/Login/Login'
import Register from '@/pages/Login/Register'
import ForgetPassword from '@/pages/Login/ForgetPassword'

import TwoStep from '@/pages/Home/TwoStep'
import Email from '@/pages/Home/Email'
import ModifyPassword from '@/pages/Home/ModifyPassword'
import TwoStepUnbind from '@/pages/Home/TwoStepUnbind'
import MyFriend from '@/pages/Home/MyFriend'
import AddContract from '@/pages/Home/AddContract'
import ContractList from '@/pages/Home/ContractList'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: {
      name: 'ModuleSwap',
    },
    children: [
      {
        path: 'swap',
        name: 'ModuleSwap',
        components: {
          tab: TabBar,
          default: ModuleSwap,
        },
      },
      {
        path: 'pool',
        name: 'ModulePool',
        components: {
          tab: TabBar,
          default: ModulePool,
        },
      },
      {
        path: 'deal',
        name: 'ModuleDeal',
        components: {
          tab: TabBar,
          default: ModuleDeal,
        },
      },
      {
        path: 'airdrop',
        name: 'ModuleAirdrop',
        components: {
          tab: TabBar,
          default: ModuleAirdrop,
        },
      },
      {
        path: 'liquidity/add',
        name: 'AddLiquidity',
        component: AddLiquidity,
      },
      {
        path: 'liquidity/:poolId',
        name: 'DecLiquidity',
        component: DecLiquidity,
      },
      {
        path: 'asset',
        name: 'MyAsset',
        component: MyAsset,
      },
      {
        path: 'asset/:assetId',
        name: 'AssetDetail',
        component: AssetDetail,
      },
      {
        path: 'asset/:assetId/recharge',
        name: 'Recharge',
        component: Recharge,
      },
      {
        path: 'asset/:assetId/withdraw',
        name: 'Withdraw',
        component: Withdraw,
      },
      {
        path: 'airdrop/record',
        name: 'AirdropRecord',
        component: AirdropRecord,
      },
      {
        path: 'two-step',
        name: 'TwoStep',
        component: TwoStep,
      },
      {
        path: 'email',
        name: 'Email',
        component: Email,
      },
      {
        path: 'modify-password',
        name: 'ModifyPassword',
        component: ModifyPassword,
      },
      {
        path: 'two-step-unbind',
        name: 'TwoStepUnbind',
        component: TwoStepUnbind,
      },
      {
        path: 'friend',
        name: 'MyFriend',
        component: MyFriend,
      },
      {
        path: 'contract/add',
        name: 'AddContract',
        component: AddContract,
      },
      {
        path: 'contract/list',
        name: 'ContractList',
        component: ContractList,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0,
    }
  },
})

router.beforeEach((to, from, next) => {
  // 授权白名单
  const whiteList = ['ModuleSwap', 'ModulePool', 'ModuleDeal', 'ModuleAirdrop', 'Login', 'Register', 'ForgetPassword']
  if (!whiteList.includes(to.name) && !getToken()) {
    next({ name: 'Login' })
    // next()
  } else {
    next()
  }

  // 设置主题
  document.title = to.meta.title ?? process.env.VUE_APP_TITLE ?? ''
  document.body.dataset.duiTheme = to.meta.theme ?? getTheme()
})

export default router
