<template lang="">
  <div>
    <div class="padding-lr">
      <nav-bar>绑定邮箱</nav-bar>
    </div>
    <template v-if="profile.email">
      <div class="card padding flex justify-between align-end">
        <div class="label">{{profile.email}}</div>
        <div class="text-gray text-sm">已绑定</div>
      </div>
    </template>
    <template v-else>
      <div class="padding-lr-sm margin-top-xl">
        <div class="item">
          <div class="label">绑定邮箱</div>
          <div class="edit flex align-center">
            <input class="flex-sub" v-model="form.email" type="email" :placeholder="$t('placeholder.inputYour', {name: $t('email')})">
            <button class="dui-button" @click="onSend" :disabled="countdown > 0">{{ countdown > 0 ? `${countdown}s` : '发送验证码'}}</button>
          </div>
        </div>
        <div class="item">
          <div class="label">邮箱验证码</div>
          <div class="edit flex align-center">
            <input v-model="form.code" type="text" placeholder="请输入您收到的验证码">
          </div>
        </div>
      </div>
      <div class="padding-lr-sm footer">
        <button class="dui-button bg-blue block lg" @click="onBind">确定绑定</button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { REG_EMAIL } from '@/commons/const'

export default {
  data () {
    return {
      countdown: 0,
      form: {
        email: '',
        code: '',
      },
    }
  },
  computed: {
    ...mapState(['profile']),
  },
  created () {
    this.getProfile()
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions(['getProfile']),
    startCountdown () {
      clearInterval(this.timer)
      this.countdown = 60
      this.timer = setInterval(() => {
        this.countdown -= 1
        if (this.countdown === 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    onSend () {
      const { email } = this.form
      if (!email) {
        return this.$toast('请输入您的邮箱')
      }
      if (!REG_EMAIL.test(email)) {
        return this.$toast('邮箱格式不正确')
      }
      this.$api.sendEmailCode({ email, action: 1 }).then(() => {
        this.$toast('已发送')
        this.startCountdown()
      })
    },
    onBind () {
      const { email, code } = this.form
      if (!email) {
        return this.$toast('请输入您的邮箱')
      }
      if (!REG_EMAIL.test(email)) {
        return this.$toast('邮箱格式不正确')
      }
      if (!code) {
        return this.$toast('请输入您收到的验证码')
      }
      this.$api.bindEmail({
        email,
        code,
      }).then(() => {
        this.$toast('绑定成功')
        this.getProfile()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../Login/login.scss';
.edit {
  .dui-button {
    margin-left: 12px;
    margin-right: -12px;
    color: var(--dui-FG);
    background-color: var(--button-bg);
  }
}
</style>
