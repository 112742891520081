<template>
  <div class="dui-page">
    <div class="header-bar">
      <div class="flex justify-between align-center">
        <a class="logo margin-left"></a>
        <div>
          <button class="dui-button login-button round" @click="$router.replace({name: 'Register'})">
            {{$t('goRegister')}}
            <icon type="right" class="margin-left-xs"></icon>
          </button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="title padding-left-xl">{{$t('login')}}</div>
      <input type="password" autocomplete="new-password" hidden>
      <div class="padding-lr-sm margin-top-xl">
        <div class="item">
          <div class="label">{{$t('account')}}</div>
          <div class="edit flex align-center">
            <input v-model="form.username" type="text" :placeholder="$t('placeholder.inputYour', {name: $t('account')})">
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('password')}}</div>
          <div class="edit flex align-center">
            <input v-model="form.password" type="password" :placeholder="$t('placeholder.inputYour', {name: $t('password')})">
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('twoStepCode')}}（选填）</div>
          <div class="edit flex align-center">
            <input v-model="form.secret" type="text" :placeholder="placeholderSecret">
          </div>
        </div>
        <p class="text-right">
          <a @click="$router.push({name: 'ForgetPassword'})">忘记密码？</a>
        </p>
      </div>
      <div class="padding-lr-sm footer">
        <button class="dui-button bg-blue block lg" @click="onLogin">{{$t('confirmLogin')}}</button>
        <div class="margin-tb text-center">
          <a @click="$router.back()">
            {{$t('backHome')}}
            <icon class="margin-left-xxs" type="right"></icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        username: '',
        password: '',
        secret: '',
      },
    }
  },
  computed: {
    placeholderSecret () {
      return this.$t('placeholder.input', { name: this.$t('twoStepCode') })
    },
  },
  methods: {
    onLogin () {
      const { username, password } = this.form
      if (!username) {
        return this.$toast(this.$t('placeholder.inputYour', { name: this.$t('account') }))
      }
      if (!password) {
        return this.$toast(this.$t('placeholder.inputYour', { name: this.$t('password') }))
      }
      this.$api.login(this.form).then(({ data }) => {
        this.$util.setToken(data.token)
        this.$router.back()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './login.scss';
</style>
