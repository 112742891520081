<template>
  <div class="asset-detail">
    <div class="card">
      <nav-bar>{{$t('assetDetails')}}</nav-bar>
      <template v-if="myAssetDetail">
        <div class="label padding-lr text-md">
          <!-- <icon class="margin-right-sm" type="eht"></icon> -->
          <div class="icon bg-img margin-right-sm round" v-src="myAssetDetail.asset.icon"></div>
          <span class="upper-case">{{myAssetDetail.asset.name}}</span>
          <span class="text-gray text-sm" style="margin-left: auto;">{{myAssetDetail.asset.contract}}</span>
        </div>
        <div class="num padding-lr margin-top-xxs">{{myAssetDetail.amount}}</div>
      </template>
      <div class="flex justify-between padding-lr padding-tb-lg">
        <button class="dui-button bg-red" @click="$router.push({name: 'Recharge'})">{{$t('recharge')}}</button>
        <button class="dui-button bg-blue" @click="$router.push({name: 'Withdraw'})">{{$t('withdraw')}}</button>
      </div>
    </div>
    <div class="tab padding-tb-sm flex justify-around">
      <a class="tab-item" :class="{selected: selected === 'allAssetLog'}" @click="onTab()">{{$t('all')}}</a>
      <a class="tab-item" :class="{selected: selected === 'rechargeAssetLog'}" @click="onTab('rechargeAssetLog')">{{$t('recharge')}}</a>
      <a class="tab-item" :class="{selected: selected === 'withdrawAssetLog'}" @click="onTab('withdrawAssetLog')">{{$t('withdraw')}}</a>
    </div>
    <div class="padding-lr-lg">
      <div class="list-item padding-tb flex align-center solid-bottom" v-for="item in list" :key="item.id">
        <div class="flex-sub">
          <div>{{item.operate_type}}</div>
          <div class="text-gray text-sm">{{item.created_at}}</div>
        </div>
        <span>{{item.amount}}</span>
      </div>
      <div v-if="nextStatus === 'more'" v-load="onLoadMore"></div>
      <div v-else-if="nextStatus === 'noMore'" class="padding-tb-lg text-gray text-center">{{$t('noMore')}}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data () {
    return {
      assetId: this.$route.params.assetId,
    }
  },
  computed: {
    ...mapState([
      'myAssetList',
      'myAssetDetail',
      'allAssetLog',
      'rechargeAssetLog',
      'withdrawAssetLog',
    ]),
    selected () {
      return this.$route.query.type ?? 'allAssetLog'
    },
    list () {
      return this[this.selected]?.list
    },
    nextStatus () {
      return this[this.selected]?.nextStatus
    },
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.onLoadMore()
  },
  mounted () {
    // 从 store 中读取资产详情
    // eslint-disable-next-line eqeqeq
    const asset = this.myAssetList.filter((item) => item.asset.id == this.assetId)?.[0]
    this.setAssetDetail(asset)
    // 获取资产详情
    this.getAssetDetail(this.assetId)
    // 获取资产变更记录
    this.onLoadMore(true)
  },
  methods: {
    ...mapMutations({
      setAssetDetail: 'SET_ASSET_DETAIL',
    }),
    ...mapActions([
      'getAssetDetail',
      'getAllAssetLogList',
      'getRechargeAssetLogList',
      'getWithdrawAssetLogList',
    ]),
    onTab (type) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type,
        },
      })
    },
    onLoadMore (isRefresh = false) {
      if (this.selected === 'rechargeAssetLog') {
        this.getRechargeAssetLogList({
          assetId: this.assetId,
          cursor: isRefresh ? null : undefined,
        })
      } else if (this.selected === 'withdrawAssetLog') {
        this.getWithdrawAssetLogList({
          assetId: this.assetId,
          cursor: isRefresh ? null : undefined,
        })
      } else {
        this.getAllAssetLogList({
          assetId: this.assetId,
          cursor: isRefresh ? null : undefined,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-gray {
  color: #8e9296;
}
body[data-dui-theme="dark"] {
  .text-gray {
    color: var(--placeholder-color);
  }
}
.card {
  margin: 16px 10px 10px;
  background-color: var(--card-bg2);
  border-radius: 10px;
  .label {
    display: flex;
    align-items: center;
  }
  .icon {
    width: 23px;
    height: 23px;
  }
  .num {
    font-size: 26px;
  }
  .dui-button {
    width: 145px;
    height: 47px;
    font-size: 16px;
    border-radius: 10px;
  }
  &.item {
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 26px 16px;
    font-size: 16px;
  }
}
.tab {
  position: sticky;
  top: 0;
  background-color: var(--dui-BG);
  z-index: 9;
  .tab-item {
    padding: 7px;
    &.selected {
      position: relative;
      color: var(--blue-color);
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 26px;
        height: 3px;
        background-color: currentColor;
        transform: translateX(-50%);
        border-radius: 3px;
      }
    }
  }
}
.list-item {
  border-color: var(--border-color);
}
</style>
