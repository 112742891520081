<template>
  <div>
    <nav-bar>添加合约</nav-bar>
    <div class="card padding-lr padding-tb-lg">
      <div class="flex justify-between align-center">
        <div class="label">输入合约地址</div>
        <CoinSelectButton buttonText="选择主网" :options="network" :coin="networkType" type="network" @select="onNetworkSelect" />
      </div>
      <div class="margin-top-xl">
        <input class="padding-lr field" v-model="contract" type="text" placeholder="请输入合约地址">
      </div>
    </div>
    <div class="footer">
      <button class="dui-button block bg-blue" @click="onSubmit">提交</button>
      <div class="margin-tb-lg text-center">
        <a class="text-sm" @click="$router.push({name: 'ContractList'})">
          申请列表
          <icon class="margin-left-xxs" type="right"></icon>
        </a>
      </div>
    </div>
    <dui-dialog ref="confirmDialog">
      <ConfirmDialog @confirm="onConfirm" @cancel="$refs.confirmDialog.close()">确定要提交吗？</ConfirmDialog>
    </dui-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CoinSelectButton from '../commons/CoinSelectButton'
import ConfirmDialog from '../commons/ConfirmDialog'

export default {
  components: {
    CoinSelectButton,
    ConfirmDialog,
  },
  data () {
    return {
      contract: '',
      networkType: {},
    }
  },
  computed: {
    ...mapState(['network']),
  },
  methods: {
    onNetworkSelect (network) {
      this.networkType = network
    },
    onSubmit () {
      if (!this.networkType.id) {
        return this.$toast('请选择主网')
      }
      if (!this.contract) {
        return this.$toast('请输入合约地址')
      }
      this.$refs.confirmDialog.open()
    },
    onConfirm () {
      this.$api.addContract({
        network_id: this.networkType.id,
        contract: this.contract,
      }).then(() => {
        this.$toast('添加成功')
        this.contract = ''
        this.networkType = {}
      }).finally(() => {
        this.$refs.confirmDialog.close()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 16px 10px 30px;
  background-color: var(--card-bg2);
  border-radius: 10px;
  .label {
    font-size: 17px;
  }
  .label::before {
    margin-right: 8px;
    content: '';
    display: inline-block;
    width: 4px;
    height: 13px;
    background-color: currentColor;
    border-radius: 5px;
  }
  .field {
    width: 100%;
    height: 42px;
    font-size: 16px;
    background-color: var(--button-bg);
    border-radius: 10px;
  }
}
.footer {
  margin: 160px 10px 30px;
  .dui-button {
    height: 55px;
    font-size: 16px;
  }
}
</style>
