<template>
  <div class="dui-page">
    <header-bar :small="showMore">
      <div slot="right" class="flex margin-top-xs">
        <button v-if="!isAuth" class="dui-button login-button round" @click="$router.push({name: 'Login'})">
          <icon type="my" class="margin-right-xs"></icon>
          {{$t('login')}}/{{$t('register')}}
        </button>
        <SettingButton v-if="showSetting" />
        <MoreButton v-if="showMore" />
        <a class="bottom-button dui-button round" href="https://v2.qkswap.io/">
          <icon type="exchange" class="margin-right-xs"></icon>切换到2.0
        </a>
      </div>
    </header-bar>
    <div class="container">
      <router-view name="tab" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MoreButton from '../commons/MoreButton'
import SettingButton from '../commons/SettingButton'

export default {
  name: 'Home',
  components: {
    MoreButton,
    SettingButton,
  },
  data () {
    return {
      isAuth: !!this.$util.getToken(),
      showMore: true,
    }
  },
  computed: {
    showSetting () {
      return this.showMore && this.isAuth
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        const whiteList = ['ModuleSwap', 'ModulePool', 'ModuleDeal', 'ModuleAirdrop', 'AddLiquidity']
        this.showMore = whiteList.includes(route.name)
      },
    },
  },
  mounted () {
    // 初始化数据
    this.getProfile({ includes: 'inviter' })
    this.getGlobalConfig()
    this.getPoolNetwork()
    this.getPoolNetworkLP()
  },
  methods: {
    ...mapActions(['getGlobalConfig', 'getPoolNetwork', 'getPoolNetworkLP', 'getProfile']),
  },
}
</script>

<style lang="scss" scoped>
.dui-page {
  .dui-button {
    padding: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--button-color);
    background-color: var(--button-bg);
  }
  .login-button {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
  }
  .bottom-button {
    position: absolute;
    margin-top: 48px;
    right: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
