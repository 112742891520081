<template>
  <div class="pool margin-top-lg">
    <a class="card bg-blue flex justify-center align-center" @click="$router.push({name: 'AddLiquidity'})">{{$t('addLiq')}}</a>
    <div class="margin-lr-sm margin-top-lg">{{$t('yourLiq')}}</div>
    <a class="item margin-tb padding-lr" v-for="pool in myPoolList" :key="pool.asset_id" @click="$router.push({name: 'DecLiquidity', params: {poolId: pool.pool.id}})">
      <div class="relative flex justify-between">
        <span class="margin-left-xs upper-case">{{pool.asset.name}}</span>
        <span>{{pool.amount}}</span>
        <span class="label">{{$t('yourPer')}}：{{(pool.percent || 0) | mul(100)}}%</span>
      </div>
      <div class="contract margin-left-xs">{{pool.asset.contract}}</div>
    </a>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['myPoolList']),
  },
  mounted () {
    this.getPool()
  },
  methods: {
    ...mapActions(['getPool']),
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 12px 10px;
  height: 80px;
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  border-radius: 10px;
  font-size: 17px;
}
.item {
  display: block;
  margin: 12px 10px;
  padding-top: 30px;
  padding-bottom: 18px;
  // height: 80px;
  background: var(--card-bg2);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  border-radius: 10px;
  font-size: 16px;
  .label {
    position: absolute;
    right: 0;
    bottom: 90%;
    font-size: 12px;
    color: #979ab6;
    white-space: nowrap;
  }
  .contract {
    font-size: 12px;
    color: #979ab6;
  }
}
</style>
