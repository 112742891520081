<template>
  <div>
    <nav-bar>空投记录</nav-bar>
    <div class="item solid-bottom" v-for="item in list" :key="item.id">
      <div class="flex align-center upper-case">
        <div class="upper-case">{{item.real_amount}} {{item.asset.name}}</div>
        <!-- <icon class="margin-lr-xs" type="right_circle"></icon>
        <div>{{item.operate_type_text}}</div> -->
      </div>
      <div class="flex justify-between text-sm margin-top-xxs">
        <span class="text-gray">{{item.created_at}}</span>
        <span :class="{'text-gray': item.status === 1, 'text-blue': item.status === 2, 'text-red': item.status === 3}">{{item.status_text}}</span>
      </div>
    </div>
    <div v-if="nextStatus === 'more'" v-load="onLoadMore"></div>
    <div v-else-if="nextStatus === 'noMore'" class="padding-tb-lg text-gray text-center">{{$t('noMore')}}</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState(['airdropLog']),
    list () {
      return this.airdropLog?.list
    },
    nextStatus () {
      return this.airdropLog?.nextStatus
    },
  },
  mounted () {
    // 获取资产变更记录
    this.onLoadMore(true)
  },
  methods: {
    ...mapActions(['getAirdropList']),
    onLoadMore (isRefresh = false) {
      this.getAirdropList({
        cursor: isRefresh ? null : undefined,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-gray {
  color: #8e9296;
}
.text-blue {
  color: #628FF3;
}
.item {
  margin: 0 15px;
  padding: 12px 0;
  font-size: 16px;
  border-color: var(--border-color);
}
body[data-dui-theme="dark"] {
  .text-gray {
    color: var(--placeholder-color);
  }
}
</style>
