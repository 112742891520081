<template lang="">
  <div>
    <div class="padding-lr">
      <nav-bar>修改密码</nav-bar>
    </div>
    <div class="padding-lr-sm margin-top-xl">
      <input type="password" autocomplete="new-password" hidden>
      <div class="item">
        <div class="label">原密码</div>
        <div class="edit flex align-center">
          <input v-model="form.old_password" type="password" :placeholder="$t('placeholder.inputYour', {name: $t('password')})">
        </div>
      </div>
      <div class="item">
        <div class="label">新密码</div>
        <div class="edit flex align-center">
          <input v-model="form.password" type="password" :placeholder="$t('placeholder.inputYour', {name: '新密码'})">
        </div>
      </div>
      <div class="item">
        <div class="label">确认密码</div>
        <div class="edit flex align-center">
          <input v-model="form.confirmPassword" type="password" placeholder="请再次确认您的密码">
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('code')}}</div>
        <div class="edit flex align-center">
          <input class="flex-sub" v-model="form.code" type="text" :placeholder="$t('placeholder.input', {name: $t('code')})" maxlength="6">
          <div class="captcha" @click="getCaptchaImage">
            <img :src="captchaUrl">
          </div>
        </div>
      </div>
    </div>
    <div class="padding-lr-sm footer">
      <button class="dui-button bg-blue block lg" @click="onSubmit">确定修改</button>
    </div>
  </div>
</template>

<script>
import validator from '@moohng/dan/src/validator'
import { passwordRule, confirmPasswordRule, codeRule } from '../Login/rule'

export default {
  data () {
    const { url, uuid } = this.$api.getCaptchaImage()
    return {
      captchaUrl: url,
      form: {
        old_password: '',
        password: '',
        confirmPassword: '',
        captcha_id: uuid,
        code: '',
      },
    }
  },
  methods: {
    getCaptchaImage () {
      const { url, uuid } = this.$api.getCaptchaImage()
      this.captchaUrl = url
      this.form.captcha_id = uuid
    },
    onSubmit () {
      const result = validator(this.form, {
        old_password: '原密码',
        password: passwordRule,
        confirmPassword: confirmPasswordRule,
        code: codeRule,
      })
      if (result.hasError()) {
        return this.$toast(result.first())
      }
      this.$api.modifyPassword(this.form).then(({ data }) => {
        this.$toast('修改成功')
        this.$router.back()
      }).catch(() => {
        this.getCaptchaImage()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../Login/login.scss';
</style>
