<template>
  <div>
    <div class="padding-lr">
      <nav-bar>绑定两部验证</nav-bar>
    </div>
    <template v-if="profile && !profile.has_google2fa_secret">
      <div class="card padding">
        <div class="label">{{$t('twoStepCode')}}</div>
        <input class="address margin-top-sm padding-lr" v-model="secret" type="text" :placeholder="placeholderSecret">
      </div>
      <div class="card padding text-center">
        <div class="qrcode" v-html="qrcode"></div>
        <p class="text-sm">请妥善保管</p>
        <div>{{key}}</div>
        <a @click="onCopy">
          <icon class="margin-right-sm" type="copy"></icon>
          <span class="text-sm">复制密钥</span>
        </a>
      </div>
      <div class="footer">
        <button v-if="os !== 'pc'" class="dui-button block bg-red" @click="onOpenAuth">
          <span>跳转到验证器绑定</span>
        </button>
        <button class="margin-top-xxl dui-button block bg-blue" @click="onBind">确定绑定</button>
      </div>
    </template>
    <template v-else>
      <div class="card padding flex justify-between align-end">
        <div class="label">两步验证</div>
        <div class="text-gray text-sm">已绑定</div>
      </div>
      <div class="space"></div>
      <div class="footer">
        <p class="text-xs text-center">*解绑两步验证，需要先绑定邮箱，解绑两步验证后，一周内不能提现。</p>
        <button class="dui-button block bg-red" @click="onUnbind">
          <span>解除绑定</span>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import QRCode from 'qrcode-svg'
import { getDevice } from '@/commons/utils'

export default {
  data () {
    return {
      secret: '',
      key: '',
      qrcode_url: '',

      os: getDevice(),
    }
  },
  computed: {
    ...mapState(['profile']),
    qrcode () {
      if (!this.qrcode_url) {
        return ''
      }
      const qrcode = new QRCode({
        content: this.qrcode_url,
        padding: 2,
        xmlDeclaration: false,
        container: 'svg-viewbox',
        join: true,
      })
      return qrcode.svg()
    },
    placeholderSecret () {
      return this.$t('placeholder.input', { name: this.$t('twoStepCode') })
    },
  },
  created () {
    this.getGoogle2fa()
  },
  methods: {
    ...mapActions(['getProfile']),
    getGoogle2fa () {
      return this.$api.getGoogle2fa().then(({ data }) => {
        this.key = data.key
        this.qrcode_url = data.qrcode_url
      })
    },
    onBind () {
      if (!this.secret) {
        return this.$toast(this.placeholderSecret)
      }
      this.$api.bindGoogle2fa({
        key: this.key,
        secret: this.secret,
      }).then(() => {
        this.$toast('绑定成功')
        this.getProfile()
      })
    },
    onCopy () {
      this.$copy(this.key)
      this.$toast(this.$t('copied'))
    },
    onOpenAuth () {
      window.location.href = `otpauth://totp/${this.profile.username}?secret=${this.key}&issuer=Qkswap`
    },
    onUnbind () {
      if (!this.profile.email) {
        return this.$toast('请先绑定邮箱')
      }
      this.$router.push({ name: 'TwoStepUnbind' })
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  --input-color: #f4f4f4;
}
.card {
  margin: 14px 10px;
  background-color: var(--card-bg2);
  border-radius: 10px;
  font-size: 17px;
  .label::before {
    margin-right: 8px;
    content: '';
    display: inline-block;
    width: 4px;
    height: 13px;
    background-color: currentColor;
    border-radius: 5px;
  }
  .address {
    width: 100%;
    height: 49px;
    font-size: 15px;
    border-radius: 10px;
    background: var(--input-color);
  }
  .icon-copy {
    width: 14px;
    height: 13px;
    vertical-align: middle;
  }
}
.qrcode {
  margin: 8px auto;
  width: 156px;
  height: 156px;
  border-radius: 4px;
  overflow: hidden;
}
.space {
  margin-top: 200px;
}
.footer {
  margin: 20px 10px 20px;
  .dui-button {
    height: 49px;
    font-size: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
