<template>
  <div class="add-liq">
    <div class="card">
      <nav-bar>{{$t('addLiq')}}</nav-bar>
      <div class="item">
        <div class="label">{{$t('inputAmount')}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="fromInput" placeholder="0.0" @input="onFromInput" />
          <CoinSelectButton :disable="[to.id]" :coin="from" @select="onFromSelect" />
        </div>
      </div>
      <div class="text-center text-sm upper-case" v-show="fromRemainAmount !== null">可用：{{fromRemainAmount}} {{from.name}}</div>
      <div class="text-center">
        <icon type="add"></icon>
      </div>
      <div class="item">
        <div class="label">{{$t('inputAmount')}}</div>
        <div class="flex justify-between align-center padding-lr-sm">
          <input class="num margin-lr-xs" v-model="toInput" placeholder="0.0" @input="onToInput" />
          <CoinSelectButton :disable="[from.id]" :coin="to" @select="onToSelect" />
        </div>
      </div>
      <div class="text-center text-sm upper-case" v-show="toRemainAmount !== null">可用：{{toRemainAmount}} {{to.name}}</div>
    </div>
    <div class="padding flex justify-between align-center text-md">
      <span class="text-gray">{{$t('tolerance')}}</span>
      <a @click="$refs.toleranceDialog.open()">{{toleranceValue|mul(100)}}%<icon class="text-gray margin-left-xs" type="edit" /></a>
    </div>
    <div v-show="predictPer" class="padding flex justify-between text-md">
      <span class="text-gray upper-case">{{$t('predictPer')}}</span>
      <span>{{predictPer}}%</span>
    </div>
    <div class="footer">
      <button class="dui-button block bg-blue" @click="onSubmit">{{$t('confirmAdd')}}</button>
    </div>
    <dui-dialog ref="toleranceDialog">
      <ToleranceDialog @close="$refs.toleranceDialog.close()" @confirm="onConfirm" />
    </dui-dialog>
  </div>
</template>

<script>
import { Decimal } from '@/commons/utils'
import { mapState } from 'vuex'
import CoinSelectButton from '../commons/CoinSelectButton'
import ToleranceDialog from '../commons/ToleranceDialog'

export default {
  components: {
    CoinSelectButton,
    ToleranceDialog,
  },
  data () {
    return {
      fromInput: '',
      toInput: '',
      selectType: null,
      toleranceValue: this.$util.getTolerance(),
      rate: null,
      lastOne: '', // from to

      submiting: false,

      baseAsset: null, // 基础资产

      fromRemainAmount: null,
      toRemainAmount: null,

      from: {},
      to: {},
    }
  },
  computed: {
    ...mapState(['network']),
    predictPer () {
      // 输入基础资产数量
      const baseAssetAmount = this.from.id < this.to.id ? this.fromInput : this.toInput
      // 基础资产余额
      const baseAssetBlance = this.from.id < this.to.id ? this.baseAsset?.spent : this.baseAsset?.gain
      if (baseAssetAmount && baseAssetBlance) {
        const val = Decimal.div(baseAssetAmount, Decimal.add(baseAssetAmount, baseAssetBlance))
        console.log(baseAssetAmount.valueOf(), baseAssetBlance, val.valueOf())
        return Decimal.mul(val, 100)
      }
      return null
    },
  },
  watch: {
    network (value) {
      if (value) {
        this.init(value)
      }
    },
  },
  created () {
    if (this.network) {
      this.init(this.network)
    }
  },
  methods: {
    init (value) {
      // const { networks, symbols } = value
      // const networkId = networks[0].id
      // this.from = symbols.filter(({ network }) => network.id === networkId)[0]
      this.from = value.symbols?.[0]
      this.$api.getAssetDetail(this.from.id).then(({ data }) => {
        this.fromRemainAmount = data.amount
      })
    },
    onFromSelect (coin) {
      this.from = coin
      this.getRate()
      this.$api.getAssetDetail(coin.id).then(({ data }) => {
        this.fromRemainAmount = data.amount
      })
    },
    onToSelect (coin) {
      this.to = coin
      this.getRate()
      this.$api.getAssetDetail(coin.id).then(({ data }) => {
        this.toRemainAmount = data.amount
      })
    },
    onConfirm (value) {
      this.toleranceValue = value
      this.$refs.toleranceDialog.close()
    },
    getRate () {
      clearTimeout(this.timer)
      if (this.from?.id && this.to?.id) {
        this.$api.getPoolRate({
          spent_id: this.from.id,
          gain_id: this.to.id,
        }).then(({ data }) => {
          this.rate = Number(data.rate)
          this.baseAsset = data
        }).catch(() => {
          this.rate = null
          this.baseAsset = null
        }).finally(() => {
          if (this.lastOne === 'from') {
            this.fromCalcTo()
          } else if (this.toInput) {
            this.toCalcFrom()
          }
          this.timer = setTimeout(() => {
            this.getRate()
          }, 1000)
        })
      }
    },
    onFromInput (e) {
      const inputValue = e.target.value
      this.fromInput = this.$util.inputNumber(inputValue)
      if (!this.fromInput) {
        // this.toInput = ''
      } else {
        this.fromCalcTo()
      }
      this.lastOne = 'from'
    },
    onToInput (e) {
      const inputValue = e.target.value
      this.toInput = this.$util.inputNumber(inputValue)
      if (!this.toInput) {
        // this.fromInput = ''
      } else {
        this.toCalcFrom()
      }
      this.lastOne = 'to'
    },
    fromCalcTo () {
      if (this.rate) {
        this.toInput = Decimal.mul(this.fromInput, this.rate)
      } else {
        // this.toInput = ''
      }
    },
    toCalcFrom () {
      if (this.rate) {
        this.fromInput = Decimal.div(this.toInput, this.rate)
      } else {
        // this.fromInput = ''
      }
    },
    onSubmit () {
      if (this.submiting) {
        return
      }
      this.submiting = true
      this.$api.createPool({
        assets: [
          {
            id: this.from.id,
            amount: this.fromInput,
            fixed: this.lastOne === 'from',
          },
          {
            id: this.to.id,
            amount: this.toInput,
            fixed: this.lastOne === 'to',
          },
        ],
        tolerance: this.toleranceValue,
      }).then(() => {
        this.$router.back()
      }).finally(() => {
        this.submiting = false
      })
    },
  },
  destroyed () {
    clearTimeout(this.timer)
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 16px 10px;
  padding-bottom: 15px;
  background-color: var(--card-bg2);
  border-radius: 10px;
}
.item {
  margin: 6px 10px;
  padding: 16px 4px;
  background-color: var(--card-bg);
  border-radius: 10px;
}
.label {
  margin-left: 18px;
  color: var(--num-color);
  font-size: 12px;
}
.num {
  width: 100px;
  flex: 0px 1 1;
  font-size: 35px;
}
.icon-add {
  width: 10px;
  height: 12px;
}
.footer {
  margin: 100px 10px 10px;
  .dui-button {
    height: 55px;
    font-size: 16px;
    border-radius: 10px;
  }
}
body[data-dui-theme="dark"] {
  .item {
    --card-bg: #2B2D61;
  }
}
</style>
