import random from '@moohng/dan/src/random'
import querystringify from '@moohng/dan/src/querystringify'
import { http } from './http'
import appConfig from '@/commons/config'
import { joinPath } from '@/commons/utils'

// 发送短信验证码
export function sendSms (data) { // type: register reset_password
  return http.post('/v1/verification-codes', data)
}

// 图形验证码
export function getCaptchaImage (uuid = random(32)) {
  const query = {
    nonce: random(32),
    v: process.env.VUE_APP_VERSION,
  }
  return {
    uuid,
    url: joinPath('/v1/captchas/' + uuid + querystringify(query, true), appConfig.appHost),
  }
}

// 全局配置
export function getGlobalConfig (data) {
  return http.get('/v1/configurations', data)
}

// 注册
export function register (data) {
  return http.post('/v1/register', data)
}

// 登出
export function logout () {
  return http.post('/v1/logout')
}

// 登录
export function login (data) {
  return http.post('/v1/login', data)
}

// 重置密码
export function resetPassword (data) {
  return http.post('/v1/reset-password', data)
}

// 所有币种
export function getPoolSymbol (params) {
  return http.get('/v1/pool/symbol', { params })
}

// 兑换汇率
export function getPoolRate (params, { silence = true } = {}) {
  return http.get('/v1/pool/rate', { params, silence })
}

// 兑换
export function poolSwap (data) {
  return http.post('/v1/pool/swap', data)
}

// 资金池
export function getPool () {
  return http.get('/v1/pool/index')
}

// 资金池
export function getPoolDetail (poolId) {
  return http.get(`/v1/pool/pools/${poolId}`)
}

// 创建流动性
export function createPool (data) {
  return http.post('/v1/pool/create', data)
}

// 减少流动性
export function subPool (data) {
  return http.post('/v1/pool/sub', data)
}

// 查看用户信息
export function getUserInfo (userId) {
  return http.get(`/v1/users/${userId}`)
}

// 查看个人信息
export function getProfile (params) {
  return http.get('/v1/profile', { params })
}

export function getExclusiveAddress () {
  return http.get('/v1/personal-address')
}

// 我的资产
export function getAssetList () {
  return http.get('/v1/assets')
}

// 我的资产
export function getAssetDetail (assetId) {
  return http.get('/v1/assets/' + assetId)
}

// 我的资产
export function getAssetLogList ({ assetId, ...params }) {
  return http.get(`/v1/assets/${assetId}/logs`, { params })
}

export function withdraw ({ assetId, ...data }) {
  return http.post(`/v1/assets/${assetId}/withdraw-logs`, data)
}

export function getPoolLogList (params) {
  return http.get('/v1/pool/log', { params })
}

export function createAirdrop (data) {
  return http.post('/v1/airdrop/create', data)
}

export function getAirdropList (params) {
  return http.get('/v1/airdrop/index', { params })
}

export function getWalletAddress () {
  return http.get('/v1/wallet-address')
}

export function bindWalletAddress (data) {
  return http.post('/v1/wallet-address', data)
}

export function getGoogle2fa () {
  return http.get('/v1/google2fa')
}

export function bindGoogle2fa (data) {
  return http.post('/v1/google2fa', data)
}

export function unbindGoogle2fa (data) {
  return http.delete('/v1/google2fa', { params: data })
}

export function getUserGoogle2fa (username) {
  return http.get(`/v1/users/${username}/google2fa`)
}

export function bindEmail (data) {
  return http.post('/v1/settings/email', data)
}

export function sendEmailCode (data) {
  return http.post('/v1/email-codes', data)
}

export function modifyPassword (data) {
  return http.post('/v1/settings/password', data)
}

export function getPoolNetwork (params) {
  return http.get('/v1/pool/network', { params })
}

export function getFriendList (params) {
  return http.get('/v1/buddies', { params })
}

export function addContract (data) {
  return http.post('/v1/token_apply', data)
}

export function getContractList (params) {
  return http.get('/v1/token_apply', { params })
}
