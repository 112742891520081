<template>
  <div class="tolerance-dialog">
    <div class="padding-lr-lg padding-tb flex align-center justify-between solid-bottom">
      <span>{{$t('setTolerance')}}</span>
      <div @click="onClose">
        <icon type="close"></icon>
      </div>
    </div>
    <div class="padding-lr-lg padding-tb flex flex-wrap justify-between">
      <a class="item margin-tb-xs text-center solid round num" :class="{'bg-blue': item == selectedValue}" v-for="item in toleranceList" :key="item" @click="onSelect(item)">{{item * 100}}<small>%</small></a>
      <div class="item margin-tb-xs flex-sub solid round num" :class="{'solid-blue': !toleranceList.some(item => item == selectedValue)}">
        <input v-model="inputValue" @input="onInput" @focus="onFocus">
        <small>%</small>
      </div>
    </div>
    <div class="padding flex align-center solid-top">
      <div class="flex-sub margin-left-sm">
        <span class="label">{{$t('tolerance')}}</span>
        <span class="num margin-left">{{showValue}}<small>%</small></span>
      </div>
      <span class="padding-left-lg padding-right-sm solid-left text-blue" @click="onConfirm">{{$t('confirmModify')}}</span>
    </div>
  </div>
</template>

<script>
import { Decimal } from '@/commons/utils'
import { TOLERANCE_VALUE } from '@/commons/const'

export default {
  data () {
    const initialValue = this.$util.getTolerance()

    return {
      toleranceList: ['0.001', '0.0025', '0.005', '0.01'],
      selectedValue: initialValue,
      inputValue: Decimal.mul(initialValue, 100),
    }
  },
  computed: {
    showValue () {
      return Decimal.mul(this.selectedValue, 100)
    },
  },
  methods: {
    onSelect (value) {
      this.selectedValue = value
      this.inputValue = Decimal.mul(value, 100)
    },
    onInput (e) {
      let inputValue = e.target.value
      inputValue = this.$util.inputNumber(inputValue)
      this.inputValue = inputValue > 100 ? 100 : inputValue
      this.selectedValue = Decimal.div(this.inputValue || 0, 100)
    },
    onFocus () {
      this.selectedValue = Decimal.div(this.inputValue || 0, 100)
    },
    onConfirm () {
      localStorage.setItem(TOLERANCE_VALUE, this.selectedValue)
      this.$emit('confirm', this.selectedValue)
    },
    onClose () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.tolerance-dialog {
  --placeholder-color: #B6B6B6;
  padding-top: 6px;
  width: 317px;
  background-color: var(--dialog-bg);
  border-radius: 10px;
}
body[data-dui-theme="dark"] {
  .tolerance-dialog {
    --placeholder-color: #757575;
  }
}
.item {
  width: 115px;
  padding: 9px;
  color: var(--placeholder-color);
  input {
    width: 220px;
    text-align: right;
  }
  &.bg-blue {
    color: #fff;
  }
  &.solid-blue {
    border-color: var(--blue-color);
  }
}
.num {
  font-size: 20px;
}
.label {
  color: #757575;
}
.solid-bottom {
  border-color: #363636;
}
.solid-top,
.solid-left {
  border-color: #d8d8d8;
}
.text-blue {
  color: #3A70DD;
}
</style>
