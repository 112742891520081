<template>
  <div class="my-asset">
    <div class="card">
      <nav-bar>{{$t('myAsset')}}</nav-bar>
      <div class="padding" v-if="editable">
        <div class="text-sm label flex">
          <div>绑定地址</div>
          <a v-if="address" class="edit" @click="editable = false">取消</a>
        </div>
        <div class="field padding-lr-sm padding-tb-xs margin-tb-sm flex">
          <input class="flex-sub" v-model="form.address" type="text" placeholder="请输入你的资产地址">
          <button class="dui-button bg-blue" @click="onBind">确定绑定</button>
        </div>
        <div class="text-xs label">* 请反复核对你的地址，以免造成不必要的损失。</div>
      </div>
      <div class="padding" v-else-if="address">
        <div class="text-sm label flex">
          <div>绑定地址<span>（已绑定）</span></div>
          <a class="edit text-blue" @click="onEdit">修改</a>
        </div>
        <div class="margin-tb-sm">{{address}}</div>
        <div class="text-xs label margin-top-lg">* 提现资产将转入你绑定的地址</div>
        <button class="margin-top-lg dui-button block bg-blue lg" @click="$router.push({name: 'AddContract'})">添加合约</button>
      </div>
    </div>
    <div class="card item" v-for="(item, index) in myAssetList" :key="index" @click="$router.push({name: 'AssetDetail', params: {assetId: item.asset.id}})">
      <div class="flex align-center justify-between">
        <span class="upper-case">{{item.asset.name}}</span>
        <span>{{item.amount}}</span>
      </div>
      <div class="contract">{{item.asset.contract}}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {
        address: '',
      },
      editable: false,
    }
  },
  computed: {
    ...mapState(['myAssetList']),
    ...mapGetters(['address']),
  },
  watch: {
    address (val) {
      this.editable = !val
    },
  },
  mounted () {
    this.getWalletAddress()
    this.getAssetList()
  },
  methods: {
    ...mapActions(['getAssetList', 'getWalletAddress']),
    onBind () {
      const { address } = this.form
      if (!address) {
        return this.$toast('请输入你的资产地址')
      }
      this.$api.bindWalletAddress({ address }).then(({ data }) => {
        this.$toast('绑定成功')
        this.$store.commit('SET_WALLET_ADDRESS', data)
      })
    },
    onEdit () {
      this.editable = true
      this.form.address = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 16px 10px 30px;
  background-color: var(--card-bg2);
  border-radius: 10px;
  &.item {
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 26px 16px;
    font-size: 16px;
  }
  .contract {
    font-size: 12px;
    color: #979ab6;
    transform: translateY(4px);
  }
}
.field {
  background-color: var(--button-bg);
  border-radius: 10px;
  .dui-button {
    padding: 0;
    width: 80px;
    height: 30px;
    font-size: 11px;
  }
}
.label {
  .edit {
    margin-left: auto;
  }
}
body[data-dui-theme="dark"] {
  .card {
    .label {
      color: #5B5D84;
    }
  }
}
</style>
