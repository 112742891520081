<template>
  <div>
    <div class="padding-lr">
      <nav-bar>解绑两步验证</nav-bar>
    </div>
    <div class="card padding flex justify-between align-center">
      <div class="label">邮箱验证</div>
      <div class="text-gray text-sm">{{profile.email}}</div>
    </div>
    <div class="padding-lr-sm margin-top-xl">
      <div class="item">
        <div class="edit flex align-center">
          <input class="flex-sub" v-model="form.code" type="text" placeholder="请输入您的邮箱验证码">
          <button class="dui-button" @click="onSend" :disabled="countdown > 0">{{ countdown > 0 ? `${countdown}s` : '获取验证码'}}</button>
        </div>
      </div>
    </div>
    <div class="padding-lr-sm footer">
      <button class="dui-button bg-blue block lg" @click="onUnbind">确定解绑</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        code: '',
      },
      countdown: 0,
    }
  },
  computed: {
    ...mapState(['profile']),
  },
  created () {
    this.getProfile()
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions(['getProfile']),
    startCountdown () {
      clearInterval(this.timer)
      this.countdown = 60
      this.timer = setInterval(() => {
        this.countdown -= 1
        if (this.countdown === 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    onSend () {
      const { email } = this.profile
      if (!email) {
        return this.$toast('请先绑定邮箱')
      }
      this.$api.sendEmailCode({ email, action: 3 }).then(() => {
        this.$toast('已发送')
        this.startCountdown()
      })
    },
    onUnbind () {
      const { code } = this.form
      if (!code) {
        return this.$toast('请输入您的邮箱验证码')
      }
      this.$api.unbindGoogle2fa({ code }).then(() => {
        this.$toast('解绑成功')
        this.$router.back()
        this.getProfile()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../Login/login.scss';
.card {
  margin: 14px 10px;
  background-color: var(--card-bg);
  border-radius: 10px;
  font-size: 17px;
  .label::before {
    margin-right: 8px;
    content: '';
    display: inline-block;
    width: 4px;
    height: 13px;
    background-color: currentColor;
    border-radius: 5px;
  }
}
.edit {
  .dui-button {
    margin-left: 12px;
    margin-right: -12px;
    color: var(--dui-FG);
    background-color: var(--button-bg);
  }
}
</style>
