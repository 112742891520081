import axios from 'axios'
import random from '@moohng/dan/src/random'
import { getToken, setToken, getDevice, toast } from '@/commons/utils'
import appConfig from '@/commons/config'

axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`
axios.defaults.timeout = 20000

const requestSuccessHandler = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getToken()}`
  config.headers.Accept = 'application/json'
  config.headers.ContentType = 'application/json'
  // 增加随机字符串参数
  config.params = {
    nonce: random(32),
    v: process.env.VUE_APP_VERSION,
    ...config.params || {},
  }
  return config
}

const responseSuccessHandler = ({ data, config }) => {
  if (data.code === 0) {
    return data
  }
  if (!config.silence) {
    toast(data.message || data.msg)
  }
  return Promise.reject(data)
}
const responseFailHandler = (error) => {
  console.log(error)
  if (error?.response?.status === 401) {
    if (getToken()) {
      setToken()
      location.reload()
    }
  } else if (error?.response?.data?.code === 30001) {
    // 客户端版本过低
    location.reload()
  } else if (!error.config.silence) {
    const msg = error.response?.data?.message || '网络错误'
    toast(msg)
  }
  return Promise.reject(error)
}

export const http = axios.create({
  baseURL: appConfig.appHost,
})
http.defaults.headers.common['cli-os'] = getDevice() || 'h5'
http.interceptors.request.use(requestSuccessHandler)
http.interceptors.response.use(responseSuccessHandler, responseFailHandler)

export default axios
