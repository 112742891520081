import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalConfig: null,
    profile: null,
    coinList: [], // 币种列表
    coinLPList: [], // 币种列表
    myPoolList: [],
    myAssetList: [],
    myAssetDetail: null,
    allAssetLog: null,
    rechargeAssetLog: null,
    withdrawAssetLog: null,
    poolLog: null,
    airdropLog: null,
    walletAddress: null,
    network: null,
    networkLP: null,
    friend: null,
    contract: null, // 合约申请列表
  },
  getters: {
    fee: state => state.globalConfig?.fee,
    airdrop_fee: state => state.globalConfig?.airdrop_fee,
    address: state => state.walletAddress?.address,
  },
  mutations: {
    SET_GLOBAL_CONFIG (state, data) {
      state.globalConfig = data
    },
    SET_PROFILE (state, data) {
      state.profile = data
    },
    SET_COIN_LIST (state, data) {
      state.coinList = data
    },
    SET_COIN_LP_LIST (state, data) {
      state.coinLPList = data
    },
    SET_POOL_LIST (state, data) {
      state.myPoolList = data
    },
    SET_ASSET_LIST (state, data) {
      state.myAssetList = data
    },
    SET_ASSET_DETAIL (state, data) {
      state.myAssetDetail = data
    },
    SET_ALL_ASSET_LOG (state, data) {
      state.allAssetLog = {
        ...state.allAssetLog,
        ...data,
      }
    },
    SET_RECHARGE_ASSET_LOG (state, data) {
      state.rechargeAssetLog = {
        ...state.rechargeAssetLog,
        ...data,
      }
    },
    SET_WITHDRAW_ASSET_LOG (state, data) {
      state.withdrawAssetLog = {
        ...state.withdrawAssetLog,
        ...data,
      }
    },
    SET_POOL_LOG (state, data) {
      state.poolLog = {
        ...state.poolLog,
        ...data,
      }
    },
    SET_AIRDROP_LOG (state, data) {
      state.airdropLog = {
        ...state.airdropLog,
        ...data,
      }
    },
    SET_WALLET_ADDRESS (state, data) {
      state.walletAddress = data
    },
    SET_NETWORK (state, data) {
      state.network = data
    },
    SET_NETWORK_LP (state, data) {
      state.networkLP = data
    },
    SET_FRIEND (state, data) {
      state.friend = {
        ...state.friend,
        ...data,
      }
    },
    SET_CONTRACT_LIST (state, data) {
      state.contract = {
        ...state.contract,
        ...data,
      }
    },
  },
  actions: {
    getGlobalConfig ({ commit }, data) {
      return api.getGlobalConfig(data).then(({ data }) => {
        commit('SET_GLOBAL_CONFIG', data)
      })
    },
    getPoolSymbol ({ commit }, data) {
      return api.getPoolSymbol(data).then(({ data }) => {
        commit('SET_COIN_LIST', data)
      })
    },
    getPoolNetwork ({ commit }, data) {
      return api.getPoolNetwork(data).then(({ data }) => {
        commit('SET_NETWORK', data)
      })
    },
    getPoolSymbolLP ({ commit }, { lp = 1, ...data } = {}) {
      return api.getPoolSymbol({ lp, ...data }).then(({ data }) => {
        commit('SET_COIN_LP_LIST', data)
      })
    },
    getPoolNetworkLP ({ commit }, { lp = 1, ...data } = {}) {
      return api.getPoolNetwork({ lp, ...data }).then(({ data }) => {
        commit('SET_NETWORK_LP', data)
      })
    },
    getProfile ({ commit }, data) {
      return api.getProfile(data).then(({ data }) => {
        commit('SET_PROFILE', data)
      })
    },
    getPool ({ commit }) {
      return api.getPool().then(({ data }) => {
        commit('SET_POOL_LIST', data.items)
      })
    },
    getAssetList ({ commit }) {
      return api.getAssetList().then(({ data }) => {
        commit('SET_ASSET_LIST', data)
      })
    },
    getAssetDetail ({ commit }, data) {
      return api.getAssetDetail(data).then(({ data }) => {
        commit('SET_ASSET_DETAIL', data)
      })
    },
    getAllAssetLogList ({ state, commit }, { per_page: perPage = 15, cursor = state.allAssetLog?.cursor, ...params }) {
      commit('SET_ALL_ASSET_LOG', { nextStatus: 'loading' })
      return api.getAssetLogList({
        ...params,
        cursor,
        per_page: perPage,
      }).then(({ data }) => {
        const list = !cursor ? data : state.allAssetLog?.list?.concat(data)
        commit('SET_ALL_ASSET_LOG', {
          list,
          nextStatus: list.length === 0 ? 'empty' : data.length < perPage ? 'noMore' : 'more',
          cursor: data[data.length - 1]?.id,
        })
      })
    },
    getRechargeAssetLogList ({ state, commit }, { per_page: perPage = 15, cursor = state.rechargeAssetLog?.cursor, ...params }) {
      commit('SET_RECHARGE_ASSET_LOG', { nextStatus: 'loading' })
      return api.getAssetLogList({
        ...params,
        cursor,
        type: 'recharge',
        per_page: perPage,
      }).then(({ data }) => {
        const list = !cursor ? data : state.rechargeAssetLog?.list?.concat(data)
        commit('SET_RECHARGE_ASSET_LOG', {
          list,
          nextStatus: list.length === 0 ? 'empty' : data.length < perPage ? 'noMore' : 'more',
          cursor: data[data.length - 1]?.id,
        })
      })
    },
    getWithdrawAssetLogList ({ state, commit }, { per_page: perPage = 15, cursor = state.rechargeAssetLog?.cursor, ...params }) {
      commit('SET_WITHDRAW_ASSET_LOG', { nextStatus: 'loading' })
      return api.getAssetLogList({
        ...params,
        cursor,
        type: 'withdraw',
        per_page: perPage,
      }).then(({ data }) => {
        const list = !cursor ? data : state.withdrawAssetLog?.list?.concat(data)
        commit('SET_WITHDRAW_ASSET_LOG', {
          list,
          nextStatus: list.length === 0 ? 'empty' : data.length < perPage ? 'noMore' : 'more',
          cursor: data[data.length - 1]?.id,
        })
      })
    },
    getPoolLogList ({ state, commit }, { per_page: perPage = 15, cursor = state.poolLog?.cursor, ...params }) {
      commit('SET_POOL_LOG', { nextStatus: 'loading' })
      return api.getPoolLogList({
        ...params,
        cursor,
        per_page: perPage,
      }).then(({ data: { items } }) => {
        const list = !cursor ? items : state.poolLog?.list?.concat(items)
        commit('SET_POOL_LOG', {
          list,
          nextStatus: list.length === 0 ? 'empty' : items.length < perPage ? 'noMore' : 'more',
          cursor: items[items.length - 1]?.id,
        })
      })
    },
    getAirdropList ({ state, commit }, { per_page: perPage = 15, cursor = state.airdropLog?.cursor, ...params }) {
      commit('SET_AIRDROP_LOG', { nextStatus: 'loading' })
      return api.getAirdropList({
        ...params,
        cursor,
        per_page: perPage,
      }).then(({ data: { items } }) => {
        const list = !cursor ? items : state.airdropLog?.list?.concat(items)
        commit('SET_AIRDROP_LOG', {
          list,
          nextStatus: list.length === 0 ? 'empty' : items.length < perPage ? 'noMore' : 'more',
          cursor: items[items.length - 1]?.id,
        })
      })
    },
    getWalletAddress ({ commit }) {
      return api.getWalletAddress().then(({ data }) => {
        commit('SET_WALLET_ADDRESS', data)
      })
    },
    getFriendList ({ state, commit }, { page = state.friend?.page ?? 1 }) {
      const perPage = 10
      return api.getFriendList({ page }).then(({ data }) => {
        const list = page === 1 ? data : state.friend?.list?.concat(data)
        commit('SET_FRIEND', {
          list,
          nextStatus: list.length === 0 ? 'empty' : data.length < perPage ? 'noMore' : 'more',
          page: page + 1,
        })
      })
    },
    getContractList ({ state, commit }, { per_page: perPage = 15, cursor = state.contract?.cursor, ...params }) {
      commit('SET_CONTRACT_LIST', { nextStatus: 'loading' })
      return api.getContractList({
        ...params,
        cursor,
        per_page: perPage,
      }).then(({ data: { items } }) => {
        const list = !cursor ? items : state.contract?.list?.concat(items)
        commit('SET_CONTRACT_LIST', {
          list,
          nextStatus: list.length === 0 ? 'empty' : items.length < perPage ? 'noMore' : 'more',
          cursor: items[items.length - 1]?.id,
        })
      })
    },
  },
  modules: {},
})
