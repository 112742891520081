<template>
  <div class="pop-button">
    <button class="dui-button more-button round margin-left-sm" @click="menuShow = true">
      <icon type="setting"></icon>
    </button>
    <div v-show="menuShow">
      <div class="pop-mask" @click="menuShow = false"></div>
      <div class="pop-panel text-sm">
        <a class="item flex align-center" @click="onTwoStep">
          <icon class="margin-right-xs" type="two_step"></icon>
          <span class="flex-sub">绑定两部验证</span>
          <icon type="right"></icon>
        </a>
        <a class="item flex align-center" @click="onEmail">
          <icon class="margin-right-xs" type="email"></icon>
          <span class="flex-sub">邮箱绑定</span>
          <icon type="right"></icon>
        </a>
        <a class="item flex align-center" @click="onModifyPassword">
          <icon class="margin-right-xs" type="password"></icon>
          <span class="flex-sub">修改密码</span>
          <icon type="right"></icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      menuShow: false,
    }
  },
  methods: {
    onTwoStep () {
      this.menuShow = false
      this.$router.push({ name: 'TwoStep' })
    },
    onEmail () {
      this.menuShow = false
      this.$router.push({ name: 'Email' })
    },
    onModifyPassword () {
      this.menuShow = false
      this.$router.push({ name: 'ModifyPassword' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/pop.scss';

.pop-button {
  .pop-panel {
    right: -44px;
  }
}
</style>
