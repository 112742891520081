const zh = {
  login: '登录',
  register: '注册',
  myAsset: '我的资产',
  myFriend: '邀请好友',
  switchMode: '切换模式',
  logout: '退出登录',

  account: '账户',
  password: '密码',
  confirmLogin: '确认登录',
  backHome: '返回首页',
  goRegister: '去注册',
  goLogin: '去登录',
  confirmPassword: '确认密码',
  code: '验证码',

  swap: '兑换',
  pool: '资金池',
  deal: '成交',
  fromLabel: 'From',
  toLabel: 'To',
  selectPass: '选择通证',
  tolerance: '公差',
  averagePrice: '均价',
  confirm: '确认',
  cancel: '取消',

  addLiq: '增加流动性',
  yourLiq: '你的流动资金',
  yourVoucher: '你的凭证数量',
  inputAmount: '输入数量',
  confirmAdd: '确定增加',
  predictPer: '预计占比',

  yourPer: '你的占比',
  reduceLiq: '减少流动性',

  assetDetails: '资产详情',
  recharge: '充值',
  withdraw: '提现',
  all: '全部',

  noMore: '没有更多了~',
  setTolerance: '设定公差',
  confirmModify: '确认更改',
  selectCoin: '选择一个币种',

  copyAddress: '复制地址',
  savePicture: '保存图片',
  walletAddress: '钱包地址',
  withdrawAmount: '提币数量',
  balance: '当前可提',
  confirmWithdraw: '确认提币',

  spentAssetType: '支出资产币种',
  spentAssetAmount: '支出资产数量',
  gainAssetType: '获得资产币种',
  gainAssetAmount: '获得资产数量',

  swapSuccess: '兑换成功',
  pleaseLogin: '请登录',
  copied: '已复制',

  withdrawSuccess: '提现成功',
  withdrawSuccessWithAudit: '提现成功，审核中',

  twoStepCode: '两步验证码',
  email: '邮箱',
  invitationCode: '邀请码',

  placeholder: {
    input: '请输入{name}',
    select: '请选择{name}',
    inputYour: '请输入您的{name}',
    inputMinWithdrawAmount: '最小提币数量',
    inputConfirmPassword: '请再次确认您的密码',
  },

  airdrop: '空投',
}

export default zh
