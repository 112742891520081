const en = {
  login: 'Login',
  register: 'Register',
  myAsset: 'My Assets',
  myFriend: 'Invite Friends',
  switchMode: 'Switch Mode',
  logout: 'Logout',

  account: 'Account',
  password: 'Password',
  confirmLogin: 'Login',
  backHome: 'Back Home',
  goRegister: 'Register',
  goLogin: 'Login',
  confirmPassword: 'Confirm Password',
  code: 'Verification Code',

  swap: 'Swap',
  pool: 'Pool',
  deal: 'Deal',
  fromLabel: 'From',
  toLabel: 'To',
  selectPass: 'Select Pass',
  tolerance: 'Tolerance',
  averagePrice: 'Average Price',
  confirm: 'Confirm',
  cancel: 'Cancel',

  addLiq: 'Add Liquidity',
  yourLiq: 'Your Liquidity',
  yourVoucher: 'Your Voucher Amount',
  inputAmount: 'Input',
  confirmAdd: 'Confirm Add',
  predictPer: 'Predict Percentage',

  yourPer: 'Your Percentage',
  reduceLiq: 'Reduce Liquidity',

  assetDetails: 'Asset Details',
  recharge: 'Recharge',
  withdraw: 'Withdraw',
  all: 'All',

  noMore: 'No More~',
  setTolerance: 'Set Tolerance',
  confirmModify: 'Confirm',
  selectCoin: 'Please Choose',

  copyAddress: 'Copy Address',
  savePicture: 'Save Picture',
  walletAddress: 'Wallet Address',
  withdrawAmount: 'Input Amount',
  balance: 'Balance',
  confirmWithdraw: 'Confirm',

  spentAssetType: 'the type of expenditure asset',
  spentAssetAmount: 'amount of expenditure assets',
  gainAssetType: 'the type of asset you obtain',
  gainAssetAmount: 'the amount of assets obtained',

  swapSuccess: 'success',
  pleaseLogin: 'Please login',
  copied: 'Copied',

  withdrawSuccess: 'Successful withdrawal',
  withdrawSuccessWithAudit: 'The withdrawal is successful, under review',

  email: 'email',
  invitationCode: 'Invitation Code',

  placeholder: {
    input: 'Please enter {name}',
    select: 'Please select {name}',
    inputYour: 'Please enter your {name}',
    inputMinWithdrawAmount: 'Minimum withdrawal amount',
    inputConfirmPassword: 'Please enter your password again',
  },

  airdrop: 'Airdrop',
}

export default en
