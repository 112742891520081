<template>
  <div class="dec-liq">
    <div class="card">
      <nav-bar></nav-bar>
      <template v-if="detail">
        <div class="item">
          <div class="label">{{$t('yourLiq')}}</div>
          <div class="flex justify-between align-center">
            <div class="upper-case">{{detail.lp_asset.name}}</div>
            <div class="text-gray text-sm upper-case">
              <div>{{detail.left_asset_amount}} {{detail.left_asset.name}}</div>
              <div>{{detail.right_asset_amount}} {{detail.right_asset.name}}</div>
            </div>
          </div>
        </div>
        <div class="dashed-line"></div>
        <div class="item">
          <div class="label">{{$t('yourVoucher')}}</div>
          <div class="margin-tb-xs">
            <input class="num" :value="detail.amount" disabled/>
          </div>
          <div class="text-gray text-sm upper-case">{{detail.left_asset.name}} 回报：{{detail.left_asset_income}}</div>
          <div class="text-gray text-sm upper-case">{{detail.right_asset.name}} 回报：{{detail.right_asset_income}}</div>
        </div>
      </template>
    </div>
    <div class="footer">
      <button class="dui-button block bg-blue" @click="$refs.confirmDialog.open()">{{$t('reduceLiq')}}</button>
    </div>
    <dui-dialog ref="confirmDialog">
      <div class="my-dialog__body padding">
        <div class="flex justify-between align-center">
          <span>减少流动性</span>
          <a @click="$refs.confirmDialog.close()"><icon type="close"></icon></a>
        </div>
        <div class="field flex align-center solid margin-top">
          <input class="flex-sub padding-lr" v-model="amount" type="text" placeholder="请输入减少数量" @input="onInputAmount">
          <span class="padding-lr-sm solid-left margin-left" @click="amount = detail.amount">全部</span>
        </div>
        <div class="text-right text-xs margin-top-xs">可用凭证数量 {{detail && detail.amount}}</div>
        <range v-model="rangeValue">{{rangeValue | mul(100)}}%</range>
        <button class="dui-button block bg-blue margin-tb" @click="onConfirm">确定</button>
      </div>
    </dui-dialog>
  </div>
</template>

<script>
import { Decimal } from '@/commons/utils'
import round from '@moohng/dan/lib/round'

export default {
  data () {
    return {
      poolId: this.$route.params.poolId,
      detail: null,
      amount: '',
    }
  },
  computed: {
    rangeValue: {
      get () {
        return round(Decimal.div(this.amount || 0, +this.detail?.amount || 0)?.toNumber(), 2)
      },
      set (val) {
        this.amount = Decimal.mul(this.detail.amount, val)?.valueOf() || ''
      },
    },
  },
  mounted () {
    this.$api.getPoolDetail(this.poolId).then(({ data }) => {
      this.detail = data
    })
  },
  methods: {
    onInputAmount (e) {
      let amount = this.$util.inputNumber(e.target.value)
      if (+amount > this.detail.amount) {
        amount = this.detail.amount
      }
      this.amount = amount
    },
    onConfirm () {
      if (!+this.amount) {
        return this.$toast('请输入减少数量')
      }
      return this.$api.subPool({
        asset_id: this.detail?.lp_asset.id,
        amount: this.amount,
      }).then(() => {
        this.$refs.confirmDialog.close()
        this.$router.back()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  margin: 16px 10px;
  padding-bottom: 10px;
  background-color: var(--card-bg2);
  border-radius: 10px;
}
.item {
  padding: 12px 16px;
  font-size: 25px;
}
.label {
  font-size: 12px;
}
.edit {
  display: inline-block;
  min-width: 10px;
  // width: min-content;
  outline: none;
}
.dashed-line {
  margin: 10px;
  border: 1px dashed var(--button-bg);
  transform: scaleY(0.5);
}
.footer {
  margin: 160px 10px 10px;
  .dui-button {
    height: 55px;
    font-size: 16px;
    border-radius: 10px;
  }
}
.my-dialog__body {
  width: 317px;
  background-color: var(--dialog-bg);
  border-radius: 10px;
  .field {
    height: 50px;
    border-radius: 5px;
    input[type="text"] {
      width: 0;
    }
  }
  .dui-button {
    height: 55px;
    font-size: 16px;
    border-radius: 10px;
  }
}
[class*="solid"] {
  border-color: #979797;
}
.text-grey {
  color: #757575;
}
input[type="text"]::placeholder {
  color: #757575;
}
</style>
