<template>
  <div class="swap margin-top-lg">
    <div class="item flex">
      <div class="label bg-red flex flex-direction align-center justify-center">
        <icon type="dot"></icon>
        <span class="margin-top-xxs">{{$t('fromLabel')}}</span>
      </div>
      <div class="flex-sub flex justify-between align-center padding-lr-sm">
        <input class="num margin-lr-xs" v-model="fromInput" type="text" placeholder="0.0" @input="onFromInput" />
        <CoinSelectButton :disable="[to.id]" :coin="from" @select="onFromSelect" />
      </div>
    </div>
    <div class="remain round text-center text-sm upper-case" v-show="fromRemainAmount !== null">可用：{{fromRemainAmount}} {{from.name}}</div>
    <div class="margin-sm text-center">
      <a @click="onExchange"><icon type="arrow-down"></icon></a>
    </div>
    <div class="item flex">
      <div class="label bg-blue flex flex-direction align-center justify-center">
        <icon type="circle"></icon>
        <span class="margin-top-xxs">{{$t('toLabel')}}</span>
      </div>
      <div class="flex-sub flex justify-between align-center padding-lr-sm">
        <input class="num margin-lr-xs" v-model="toInput" type="text" placeholder="0.0" @input="onToInput" />
        <CoinSelectButton :disable="[from.id]" :coin="to" @select="onToSelect" />
      </div>
    </div>
    <div class="remain round text-center text-sm upper-case" v-show="toRemainAmount !== null">可用：{{toRemainAmount}} {{to.name}}</div>
    <div v-show="averagePrice" class="padding-lr margin-top-lg flex justify-between align-center text-md">
      <span class="text-gray">{{$t('averagePrice')}}</span>
      <span class="upper-case">1{{from.name}}={{averagePrice}}{{to.name}}</span>
    </div>
    <div v-show="fromAmount" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">{{from.name}}</span>
      <span>{{fromAmount}}</span>
    </div>
    <div v-show="toAmount" class="padding-lr margin-tb-sm flex justify-between text-md">
      <span class="text-gray upper-case">{{to.name}}</span>
      <span>{{toAmount}}</span>
    </div>
    <div class="padding-lr margin-tb-sm flex justify-between align-center text-md">
      <span class="text-gray">{{$t('tolerance')}}</span>
      <a @click="$refs.toleranceDialog.open()">{{toleranceValue|mul(100)}}%<icon class="text-gray margin-left-xs" type="edit" /></a>
    </div>
    <div class="footer">
      <button class="dui-button block bg-blue" @click="onSubmit">{{$t('confirm')}}</button>
    </div>
    <dui-dialog ref="toleranceDialog">
      <ToleranceDialog @close="$refs.toleranceDialog.close()" @confirm="onConfirm" />
    </dui-dialog>
    <dui-dialog ref="confirmDialog">
      <ConfirmDialog content="确定操作吗？" @confirm="onConfirmSubmit" @cancel="$refs.confirmDialog.close()" />
    </dui-dialog>
  </div>
</template>

<script>
import { Decimal } from '@/commons/utils'
import { mapState, mapGetters } from 'vuex'
import CoinSelectButton from '../commons/CoinSelectButton'
import ToleranceDialog from '../commons/ToleranceDialog'
import ConfirmDialog from '../commons/ConfirmDialog'

export default {
  components: {
    CoinSelectButton,
    ToleranceDialog,
    ConfirmDialog,
  },
  data () {
    return {
      fromInput: '',
      toInput: '',
      selectType: null,
      toleranceValue: this.$util.getTolerance(),
      lastOne: '', // from to

      fromAmount: 0, // 资金池剩余数量
      toAmount: 0,

      submiting: false,
      averagePrice: null,

      fromRemainAmount: null,
      toRemainAmount: null,

      from: {},
      to: {},
    }
  },
  computed: {
    ...mapState(['network']),
    ...mapGetters(['fee']),
  },
  watch: {
    network (value) {
      if (value) {
        this.init(value)
      }
    },
  },
  created () {
    if (this.network) {
      this.init(this.network)
    }
  },
  methods: {
    init (value) {
      // const { networks, symbols } = value
      // const networkId = networks[0].id
      // this.from = symbols.filter(({ network }) => network.id === networkId)[0]
      this.from = value.symbols?.[0]
      this.$api.getAssetDetail(this.from.id).then(({ data }) => {
        this.fromRemainAmount = data.amount
      })
    },
    onFromSelect (coin) {
      this.from = coin
      this.getRate()
      this.$api.getAssetDetail(coin.id).then(({ data }) => {
        this.fromRemainAmount = data.amount
      })
    },
    onToSelect (coin) {
      this.to = coin
      this.getRate()
      this.$api.getAssetDetail(coin.id).then(({ data }) => {
        this.toRemainAmount = data.amount
      })
    },
    onConfirm (value) {
      this.toleranceValue = value
      this.$refs.toleranceDialog.close()
    },
    getRate () {
      clearTimeout(this.timer)
      if (this.from?.id && this.to?.id) {
        this.$api.getPoolRate({
          spent_id: this.from.id,
          gain_id: this.to.id,
        }).then(({ data }) => {
          this.fromAmount = data.spent
          this.toAmount = data.gain
        }).catch(() => {
          this.fromAmount = 0
          this.toAmount = 0
        }).finally(() => {
          // 计算兑换
          if (this.lastOne === 'from') {
            this.fromInput && (this.toInput = this.fromCalcTo(this.fromInput))
          } else if (this.toInput) {
            this.toInput && this.toCalcFrom()
          }

          // 计算均价
          if (this.fromInput && this.toInput) {
            this.averagePrice = Decimal.div(this.toInput, this.fromInput)
          }

          this.timer = setTimeout(() => {
            this.getRate()
          }, 1000 * 10)
        })
      }
    },
    onFromInput (e) {
      const inputValue = e.target.value
      this.fromInput = this.$util.inputNumber(inputValue)
      if (!this.fromInput) {
        this.toInput = ''
      } else {
        this.toInput = this.fromCalcTo(this.fromInput)
      }
      this.lastOne = 'from'
    },
    onToInput (e) {
      const inputValue = e.target.value
      this.toInput = this.$util.inputNumber(inputValue)
      if (!this.toInput) {
        this.fromInput = ''
      } else {
        this.toCalcFrom()
      }
      this.lastOne = 'to'
    },
    fromCalcTo (value = this.fromInput) {
      if (this.fromAmount && this.toAmount) {
        // this.toAmount - x = this.fromAmount * this.toAmount / (this.fromAmount + this.fromInput * (1 - this.fee))
        // 减去手续费
        const inputAmount = Decimal.mul(value, Decimal.sub(1, this.fee))
        // 资金池当前 from 的数量 = 已有from数量 + 用户输入的数量（已减去手续费）
        const currentFromAmount = Decimal.add(this.fromAmount, inputAmount)
        // 资金池 k (不变)
        const k = Decimal.mul(this.fromAmount, this.toAmount)
        // 资金池当前 to 的数量 =  k / 当前from数量 (k 不变，from增加，当前to必定减少)
        const currentToAmount = Decimal.div(k, currentFromAmount)
        // 可以兑换的数量 = 已有 to 的数量 - 资金池当前 to 的数量
        return Decimal.sub(this.toAmount, currentToAmount)
      }
      return ''
    },
    toCalcFrom () {
      if (this.fromAmount && this.toAmount && Decimal.sub(this.toAmount, this.toInput) > 0) {
        // 同上
        // this.fromAmount + x * (1 - this.fee)  =  this.fromAmount * this.toAmount / (this.toAmount - this.toInput)
        // 当前资金池 to 数量 = 已有 to 数量 + 输入数量
        const currentToAmount = Decimal.sub(this.toAmount, this.toInput)
        // k
        const k = Decimal.mul(this.fromAmount, this.toAmount)
        // 当前资金池 from 数量
        const currentFromAmount = Decimal.div(k, currentToAmount)
        // 可兑换的 from 数量 + 手续费 = 资金池已有 from 数量 - 当前 from 数量
        const gainFromAmount = Decimal.sub(currentFromAmount, this.fromAmount)
        // 减去手续费
        this.fromInput = Decimal.div(gainFromAmount, Decimal.sub(1, this.fee))
      } else {
        this.fromInput = ''
      }
    },
    onExchange () {
      [this.from, this.to] = [this.to, this.from];
      [this.fromRemainAmount, this.toRemainAmount] = [this.toRemainAmount, this.fromRemainAmount];
      [this.fromInput, this.toInput] = [this.toInput, this.fromInput]
      this.lastOne = this.lastOne === 'from' ? 'to' : 'from'
      this.getRate()
    },
    onSubmit () {
      if (this.submiting) {
        return
      }
      if (!this.from?.id) {
        return this.$toast(this.$t('placeholder.select', { name: this.$t('spentAssetType') }))
      }
      if (!this.fromInput) {
        return this.$toast(this.$t('placeholder.input', { name: this.$t('spentAssetAmount') }))
      }
      if (!this.to?.id) {
        return this.$toast(this.$t('placeholder.select', { name: this.$t('gainAssetType') }))
      }
      if (!this.toInput) {
        return this.$toast(this.$t('placeholder.input', { name: this.$t('gainAssetAmount') }))
      }
      this.$refs.confirmDialog.open()
    },
    onConfirmSubmit () {
      this.submiting = true
      this.$api.poolSwap({
        spent_id: this.from?.id,
        spent_amount: this.fromInput,
        gain_id: this.to?.id,
        gain_amount: this.toInput,
        tolerance: this.toleranceValue,
        spent_fixed: this.lastOne === 'from',
      }).then(() => {
        this.$toast(this.$t('swapSuccess'))
        this.fromInput = ''
        this.toInput = ''
        this.getRate()
      }).catch((error) => {
        console.log(error)
        if (error.response?.status === 401) {
          this.$toast(this.$t('pleaseLogin'))
        }
      }).finally(() => {
        this.submiting = false
        this.$refs.confirmDialog.close()
      })
    },
  },
  destroyed () {
    clearTimeout(this.timer)
  },
}
</script>

<style lang="scss" scoped>
.icon-arrow-down {
  width: 10px;
  height: 12px;
}
.icon-edit {
  width: 14px;
  height: 14px;
}
.item {
  margin: 8px 10px;
  height: 80px;
  border-radius: 10px;
  background-color: var(--card-bg2);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  overflow: hidden;
  .text-gray {
    color: #8E9296;
  }
}
.label {
  width: 48px;
  font-size: 12px;
  text-align: center;
  .icon-dot,
  .icon-circle {
    width: 12px;
    height: 12px;
  }
}
.num {
  width: 100px;
  flex: 0px 1 1;
  font-size: 35px;
}
.remain {
  margin: 8px 10px;
  line-height: 2;
  color: #fff;
  background-color: #818692;
}
body[data-dui-theme="dark"] {
  .item {
    .text-gray {
      color: var(--placeholder-color);
    }
  }
  .remain {
    background-color: var(--card-bg);
  }
}
.footer {
  margin: 100px 10px 30px;
  .dui-button {
    height: 55px;
    font-size: 16px;
  }
}
</style>
