import Vue from 'vue'
import Big from 'big.js'
import { APP_TOKEN, APP_THEME, TOLERANCE_VALUE, REG_INPUT_NUMBER } from './const'

export function getToken () {
  return window.localStorage.getItem(APP_TOKEN)
}

export function setToken (token) {
  if (!token) {
    return window.localStorage.removeItem(APP_TOKEN)
  }
  return window.localStorage.setItem(APP_TOKEN, token)
}

export function joinPath (url, host) {
  if (/(http|https):\/\/([\w.]+\/?)\S*/.test(url)) {
    return url
  }
  if (url && host) {
    return `${host.replace(/\/+$/, '')}/${url.replace(/^\/+/, '')}`
  }
  return url
}

export function timestampFormat (timestamp, fmt = 'DD天 HH小时 mm分 ss秒') {
  const perSecond = 1000
  const perMinute = 60 * perSecond
  const perHour = 60 * perMinute
  const perDay = 24 * perHour

  const days = Math.floor(timestamp / perDay)
  const hours = Math.floor((timestamp - days * perDay) / perHour)
  const minutes = Math.floor((timestamp - days * perDay - hours * perHour) / perMinute)
  const seconds = Math.floor((timestamp - days * perDay - hours * perHour - minutes * perMinute) / perSecond)

  return fmt.replace(/DD|HH|mm|ss/g, (matched) => {
    switch (matched) {
      case 'DD':
        return String(days).padStart(2, '0')
      case 'HH':
        return String(hours).padStart(2, '0')
      case 'mm':
        return String(minutes).padStart(2, '0')
      case 'ss':
        return String(seconds).padStart(2, '0')
      default:
        return ''
    }
  })
}

export function splitBy (arr, count) {
  if (!Array.isArray(arr)) {
    return arr
  }
  const results = []
  let temp = []
  arr.forEach((item, index) => {
    temp = temp.concat(item)
    if (!(index + 1) % count) {
      results.push(temp)
      temp = []
    }
  })
  if (temp.length) {
    results.push(temp)
  }
  return results
}

export function toLowerCase (str, defaultValue = '') {
  return str ? String.prototype.toLocaleLowerCase.call(str) : defaultValue
}

export function toUpperCase (str, defaultValue = '') {
  return str ? String.prototype.toLocaleUpperCase.call(str) : defaultValue
}

export function getDevice () {
  const ua = window.navigator.userAgent
  if (/iPhone|iPad|iPod|iOS/i.test(ua)) {
    return 'ios'
  }
  if (/Android/i.test(ua)) {
    return 'android'
  }
  return 'pc'
}

export function toast (text) {
  const vm = new Vue()
  return vm.$toast(text)
}

export function toImage (img) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.onload = function onload () {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      canvas.getContext('2d').drawImage(image, 0, 0)
      const src = canvas.toDataURL('image/jpeg')
      resolve(src)
    }
    image.onerror = reject
    image.src = img
  })
}

export function checkWebp () {
  try {
    return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
  } catch (err) {
    //
  }
  return false
}

export function toggleTheme (theme) {
  if (!theme) {
    const currentTheme = getTheme()
    theme = currentTheme === 'light' ? 'dark' : 'light'
  }
  document.body.dataset.duiTheme = theme
  localStorage.setItem(APP_THEME, theme)
  return theme
}

export function getTheme () {
  return localStorage.getItem(APP_THEME) ?? process.env.VUE_APP_THEME ?? 'light'
}

export function getTolerance () {
  return localStorage.getItem(TOLERANCE_VALUE) ?? process.env.VUE_APP_TOLERANCE ?? 0.005
}

export function inputNumber (val = '') {
  return val.match(REG_INPUT_NUMBER)[0]
}

Big.DP = 18
Big.NE = -19

window.Big = Big

export const Decimal = {
  add (a, b) {
    try {
      return Big(a).add(Big(b))
    } catch {
    }
  },
  sub (a, b) {
    try {
      return Big(a).sub(Big(b))
    } catch {
    }
  },
  mul (a, b) {
    try {
      return Big(a).mul(Big(b))
    } catch {
    }
  },
  div (a, b) {
    try {
      return Big(a).div(Big(b))
    } catch {
    }
  },
}
