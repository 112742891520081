<template>
  <div class="pop-button">
    <button class="dui-button more-button round margin-left-sm" @click="menuShow = true">
      <icon type="more"></icon>
    </button>
    <div v-show="menuShow">
      <div class="pop-mask" @click="menuShow = false"></div>
      <div class="pop-panel text-sm">
        <div class="item flex align-center solid-bottom" v-if="profile">
          <icon class="margin-right-xs" type="my"></icon>
          <div class="over-hidden">
            <div class="text-white text-cut">{{profile.username}}</div>
            <div class="upper-case margin-top-xxs">uid:{{profile.id}}</div>
          </div>
        </div>
        <a class="item flex align-center" @click="onMyAsset">
          <icon class="margin-right-xs" type="asset"></icon>
          <span class="flex-sub">{{$t('myAsset')}}</span>
          <icon type="right"></icon>
        </a>
        <a class="item flex align-center" @click="onMyFriend">
          <icon class="margin-right-xs" type="friend"></icon>
          <span class="flex-sub">{{$t('myFriend')}}</span>
          <icon type="right"></icon>
        </a>
        <a class="item flex align-center" @click="onThemeToggle">
          <icon class="margin-right-xs" :type="isDark ? 'light' : 'dark'"></icon>
          <span class="flex-sub">{{$t('switchMode')}}</span>
          <icon type="right"></icon>
        </a>
        <a class="item flex align-center" v-if="$util.getToken()" @click="onLogout">
          <icon class="margin-right-xs" type="logout"></icon>
          <span class="flex-sub">{{$t('logout')}}</span>
          <icon type="right"></icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      menuShow: false,
      isDark: this.$util.getTheme() === 'dark',
    }
  },
  computed: {
    ...mapState(['profile']),
  },
  methods: {
    onMyAsset () {
      this.menuShow = false
      this.$router.push({ name: 'MyAsset' })
    },
    onMyFriend () {
      this.menuShow = false
      this.$router.push({ name: 'MyFriend' })
    },
    onThemeToggle () {
      this.menuShow = false
      const theme = this.$util.toggleTheme()
      this.isDark = theme === 'dark'
    },
    onLogout () {
      this.menuShow = false
      this.$api.logout().then(() => {
        this.$util.setToken()
        location.reload()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/pop.scss';
</style>
