<template>
  <div class="charge">
    <div class="padding-lr">
      <nav-bar>{{$t('withdraw')}}</nav-bar>
    </div>
    <div class="card padding">
      <div class="label">{{$t('walletAddress')}}</div>
      <!-- <input class="address margin-top-sm padding-lr" v-model="address" type="text" :placeholder="placeholderAddress"> -->
      <div class="margin-top-sm">{{address}}</div>
    </div>
    <div class="card padding">
      <div class="flex justify-between align-center">
        <div class="label">{{$t('withdrawAmount')}}</div>
        <div class="tip-text upper-case" v-if="myAssetDetail">{{$t('balance')}}：{{myAssetDetail.amount}} {{myAssetDetail.asset.name}}</div>
      </div>
      <input class="address margin-top-sm padding-lr" v-model="amount" type="text" :placeholder="placeholderAmount" @input="onInputAmount">
    </div>
    <div class="card padding">
      <div class="flex justify-between align-center">
        <div class="label">{{$t('twoStepCode')}}（选填）</div>
      </div>
      <input class="address margin-top-sm padding-lr" v-model="secret" type="text" :placeholder="placeholderSecret">
    </div>
    <div class="margin-lr tip-text upper-case" v-if="myAssetDetail">提现手续费：{{myAssetDetail.asset.withdraw_fee}} {{myAssetDetail.asset.name}}</div>
    <div class="footer">
      <button class="dui-button block bg-blue" @click="onWithdraw">{{$t('confirmWithdraw')}}</button>
    </div>
    <dui-dialog ref="confirmDialog">
      <ConfirmDialog @cancel="$refs.confirmDialog.close()" @confirm="onConfirm">
        <div class="upper-case" v-if="myAssetDetail && amount">实际到账{{amount | sub(myAssetDetail.asset.withdraw_fee)}} {{myAssetDetail.asset.name}}</div>
        <div>确定提现吗？</div>
      </ConfirmDialog>
    </dui-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ConfirmDialog from '../commons/ConfirmDialog'

export default {
  components: {
    ConfirmDialog,
  },
  data () {
    return {
      amount: '',
      secret: '',

      submiting: false,
    }
  },
  computed: {
    ...mapState(['myAssetDetail']),
    ...mapGetters(['address']),
    placeholderAddress () {
      return this.$t('placeholder.inputYour', { name: this.$t('walletAddress') })
    },
    placeholderSecret () {
      return this.$t('placeholder.input', { name: this.$t('twoStepCode') })
    },
    placeholderAmount () {
      return this.myAssetDetail?.asset.min_withdraw_amount > 0 ? `${this.$t('placeholder.inputMinWithdrawAmount')}：${this.myAssetDetail?.asset.min_withdraw_amount} ${this.$util.toUpperCase(this.myAssetDetail?.asset.name)}` : ''
    },
    assetId () {
      return this.$route.params.assetId
    },
  },
  mounted () {
    // 获取资产详情
    this.getAssetDetail(this.assetId)
    this.getWalletAddress().then(() => {
      if (!this.address) {
        this.$toast('请先绑定地址')
        setTimeout(() => {
          this.$router.go(-2)
        }, 2000)
      }
    })
  },
  methods: {
    ...mapActions([
      'getAssetDetail',
      'getWalletAddress',
    ]),
    onInputAmount (e) {
      const { value } = e.target
      this.amount = this.$util.inputNumber(value)
    },
    onWithdraw () {
      if (!this.address) {
        return this.$toast(this.placeholderAddress)
      }
      if (Number(this.myAssetDetail?.asset.min_withdraw_amount) === 0 && this.amount <= 0) {
        return this.$toast('最小提现数量必须大于0')
      }
      if (this.amount < Number(this.myAssetDetail?.asset.min_withdraw_amount)) {
        return this.$toast(this.placeholderAmount)
      }
      if (this.amount <= Number(this.myAssetDetail?.asset.withdraw_fee)) {
        return this.$toast(`最小提现数量必须大于${this.myAssetDetail?.asset.withdraw_fee}`)
      }
      // if (!this.secret) {
      //   return this.$toast(this.placeholderSecret)
      // }

      this.$refs.confirmDialog.open()
    },
    onConfirm () {
      if (this.submiting) {
        return
      }
      this.$refs.confirmDialog.close()
      this.submiting = true
      this.$api.withdraw({
        assetId: this.assetId,
        amount: this.amount,
        secret: this.secret,
      }).then(() => {
        if (this.amount >= this.myAssetDetail?.asset.min_withdraw) {
          this.$toast(this.$t('withdrawSuccessWithAudit'))
        } else {
          this.$toast(this.$t('withdrawSuccess'))
        }
        this.$router.back()
      }).finally(() => {
        this.submiting = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  --input-color: #f4f4f4;
}
.card {
  margin: 14px 10px;
  background-color: var(--card-bg2);
  border-radius: 10px;
  font-size: 17px;
  .label::before {
    margin-right: 8px;
    content: '';
    display: inline-block;
    width: 4px;
    height: 13px;
    background-color: currentColor;
    border-radius: 5px;
  }
  .address {
    width: 100%;
    height: 49px;
    font-size: 15px;
    border-radius: 10px;
    background: var(--input-color);
  }
}
.tip-text {
  font-size: 12px;
  color: var(--placeholder-color);
}
.footer {
  margin: 84px 10px 20px;
  .dui-button {
    height: 55px;
    font-size: 16px;
    border-radius: 10px;
  }
}
</style>
