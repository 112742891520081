<template>
  <div class="deal margin-top-lg">
    <div class="item solid-bottom" v-for="item in list" :key="item.id">
      <div class="flex align-center">
        <div class="icon-create-liq margin-right-xs" v-if="item.operate_type === 'create_liquidity'"></div>
        <icon class="margin-right-xs" v-else :type="item.operate_type"></icon>
        <div class="flex-sub">{{item.operate_type_text}}</div>
        <span class="upper-case text-sm">{{item.left_asset_amount}} {{item.left_asset_name}}</span>
      </div>
      <div class="flex justify-between margin-top-xs">
        <span class="text-gray text-sm">{{item.created_at_humans}}</span>
        <span class="upper-case text-sm">{{item.right_asset_amount}} {{item.right_asset_name}}</span>
      </div>
    </div>
    <div v-if="nextStatus === 'more'" v-load="onLoadMore"></div>
    <div v-else-if="nextStatus === 'noMore'" class="padding-tb-lg text-gray text-center">{{$t('noMore')}}</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState(['poolLog']),
    list () {
      return this.poolLog?.list
    },
    nextStatus () {
      return this.poolLog?.nextStatus
    },
  },
  mounted () {
    // 获取资产变更记录
    this.onLoadMore(true)
  },
  methods: {
    ...mapActions(['getPoolLogList']),
    onLoadMore (isRefresh = false) {
      this.getPoolLogList({
        cursor: isRefresh ? null : undefined,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-gray {
  color: #8e9296;
}
.item {
  margin: 0 15px;
  padding: 15px 0;
  border-color: var(--border-color);
  .icon-create-liq {
    width: 16px;
    height: 18px;
    background: url("../../assets/images/create-liq.png") center / contain no-repeat;
  }
}
body[data-dui-theme="dark"] {
  .icon-create-liq {
    width: 18px;
    height: 19px;
    background-image: url("../../assets/images/create-liq-dark.png");
  }
  .text-gray {
    color: var(--placeholder-color);
  }
}
</style>
