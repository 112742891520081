<template>
  <div class="airdrop">
    <div class="item padding-lr padding-tb-lg">
      <div class="flex justify-between align-end">
        <div class="label">空投资产类型</div>
        <div v-if="myAssetDetail" class="text-sm">可用余额 {{myAssetDetail.amount}}</div>
      </div>
      <div class="flex justify-between margin-top-sm">
        <CoinSelectButton buttonText="选择空投资产" :options="networkLP" :coin="airdropAssetType" @select="onAirdropSelect" />
        <input class="amount flex-sub margin-left" type="text" v-model="amount" placeholder="0.0" @input="onInputAmount">
      </div>
    </div>
    <div class="item padding-lr padding-tb-lg">
      <div class="flex justify-between align-center">
        <div class="label">比例计算资产</div>
        <CoinSelectButton buttonText="选择资产" :options="networkLP" :coin="calcAssetType" @select="onCalcSelect" />
      </div>
    </div>
    <div class="margin-lr-sm text-sm content">
      <div class="title">* 注意事项</div>
      <div class="desc">1.请核对好您选择的空投资产，资产将空投给符合条件的用户，空投成功后不可退回</div>
      <div class="desc">2.每次空投将扣除 {{airdrop_fee}} QUSDT手续费</div>
      <div class="desc">3.空投接收人不会包含空投发起人</div>
    </div>
    <div class="footer">
      <button class="dui-button block bg-blue" @click="onSubmit">提交空投</button>
      <div class="margin-tb-lg text-center">
        <a class="text-sm" @click="$router.push({name: 'AirdropRecord'})">
          空投记录
          <icon class="margin-left-xxs" type="right"></icon>
        </a>
      </div>
    </div>
    <dui-dialog ref="confirmDialog">
      <div class="confirm-dialog text-center">
        <a class="close-btn" @click="$refs.confirmDialog.close()">
          <icon type="close"></icon>
        </a>
        <div class="icon bg-img round" v-src="airdropAssetType.icon"></div>
        <div class="num margin-top-sm upper-case">{{amount}} {{airdropAssetType.name}}<br>空投资产</div>
        <div class="margin-top-sm text-gray">请核对好您的空投资产<br>-{{airdrop_fee}} QUSDT 手续费</div>
        <button class="dui-button block bg-blue margin-top" @click="onConfirm">确定空投</button>
      </div>
    </dui-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CoinSelectButton from '../commons/CoinSelectButton'

export default {
  components: {
    CoinSelectButton,
  },
  data () {
    return {
      amount: '',

      airdropAssetType: {},
      calcAssetType: {},
    }
  },
  computed: {
    ...mapState(['networkLP', 'myAssetDetail']),
    ...mapGetters(['airdrop_fee']),
  },
  methods: {
    ...mapActions(['getAssetDetail']),
    onAirdropSelect (asset) {
      this.airdropAssetType = asset
      this.$store.commit('SET_ASSET_DETAIL', null)
      this.getAssetDetail(asset.id)
    },
    onCalcSelect (asset) {
      this.calcAssetType = asset
    },
    onInputAmount (e) {
      const { value } = e.target
      this.amount = this.$util.inputNumber(value)
    },
    onSubmit () {
      if (!this.airdropAssetType.id) {
        return this.$toast('请选择空投资产')
      }
      if (!this.amount) {
        return this.$toast('请输入空投资产')
      }
      if (!this.calcAssetType.id) {
        return this.$toast('请选择比例计算资产')
      }
      this.$refs.confirmDialog.open()
    },
    onConfirm () {
      this.$refs.confirmDialog.close()
      this.$api.createAirdrop({
        asset_id: this.airdropAssetType.id,
        amount: this.amount,
        percent_asset_id: this.calcAssetType.id,
      }).then(() => {
        this.$toast('空投成功')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  margin: 20px 10px;
  border-radius: 10px;
  background-color: var(--card-bg2);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.0299388);
  overflow: hidden;
  .label {
    font-size: 17px;
  }
  .label::before {
    margin-right: 8px;
    content: '';
    display: inline-block;
    width: 4px;
    height: 13px;
    background-color: currentColor;
    border-radius: 5px;
  }
  .text-gray {
    color: #8E9296;
  }
  .amount {
    padding-left: 16px;
    padding-right: 16px;
    height: 42px;
    width: 60%;
    // color: var(--dui-FG);
    font-size: 18px;
    background-color: var(--button-bg);
    border-radius: 10px;
  }
}
.content {
  line-height: 1.8;
}
body[data-dui-theme*="dark"] {
  .title {
    color: #628FF3;
  }
  .desc {
    color: #BBC0FF;
  }
}
.desc {
  color: #9F9FAD;
}
.footer {
  margin: 110px 10px 30px;
  .dui-button {
    height: 55px;
    font-size: 16px;
  }
}
.confirm-dialog {
  padding: 30px 22px 20px;
  width: 285px;
  line-height: 1.9;
  background-color: var(--dialog-bg);
  border-radius: 10px;
  .icon {
    display: inline-block;
    width: 70px;
    height: 70px;
  }
  .num {
    font-size: 20px;
    line-height: 1.6;
  }
  .dui-button {
    height: 55px;
    font-size: 16px;
  }
  .close-btn {
    position: absolute;
    margin-top: -12px;
    margin-left: 140px;
    font-size: 0;
  }
}
</style>
