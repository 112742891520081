<template>
  <div class="recharge">
    <div class="padding-lr">
      <nav-bar>{{$t('recharge')}}</nav-bar>
    </div>
    <section v-if="bindAddress && address">
      <div class="card padding text-center">
        <div class="qrcode" v-html="getQrcode(address)"></div>
        <div>请使用您的绑定地址<br><span class="text-red">{{bindAddress}}</span><br>转入资产到<br>{{address}}</div>
        <div v-if="minRecharge > 0" class="text-sm text-gray margin-top-sm">最小充值金额: {{minRecharge}}, 小于最小充值金额, 资产将无法找回</div>
        <div class="text-sm text-gray margin-top-sm">充值前请确认转入地址与您绑定地址是否一致，否则将无法找回。</div>
      </div>
      <div class="footer">
        <button class="dui-button block bg-blue" @click="onCopy(address)">
          <icon class="margin-right-sm" type="copy"></icon>
          <span>{{$t('copyAddress')}}</span>
        </button>
      </div>
    </section>
    <section v-if="personalAddress">
      <div class="card padding text-center">
        <div class="qrcode" v-html="getQrcode(personalAddress)"></div>
        <div>{{personalAddress}}</div>
        <div v-if="minRecharge > 0" class="text-sm text-gray margin-top-sm">最小充值金额: {{minRecharge}}, 小于最小充值金额, 资产将无法找回</div>
      </div>
      <div class="footer">
        <button class="dui-button block bg-blue" @click="onCopy(personalAddress)">
          <icon class="margin-right-sm" type="copy"></icon>
          <span>{{$t('copyAddress')}}</span>
        </button>
        <!-- <button class="dui-button block bg-blue margin-top">
          <icon class="margin-right-sm" type="save"></icon>
          <span>{{$t('savePicture')}}</span>
        </button> -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import QRCode from 'qrcode-svg'

export default {
  data () {
    return {
      address: '',
      bindAddress: '',

      personalAddress: '',
    }
  },
  computed: {
    ...mapState(['myAssetDetail']),
    assetId () {
      return this.$route.params.assetId
    },
    minRecharge () {
      return this.myAssetDetail?.asset?.min_recharge
    },
  },
  mounted () {
    // 获取资产详情
    this.getAssetDetail(this.assetId).then(() => {
      const {
        recharge_bind: rechageBind,
        recharge_address: rechargeAddress,
        recharge_exclusive: rechargeExclusive,
      } = this.myAssetDetail?.asset || {}
      if (rechageBind === 1) {
        this.$api.getWalletAddress().then(({ data }) => {
          if (!data.address) {
            if (rechargeExclusive !== 1) {
              this.$toast('请先绑定地址')
              setTimeout(() => {
                this.$router.go(-2)
              }, 2000)
            }
          } else {
            this.bindAddress = data.address
            this.address = rechargeAddress
          }
        })
      }
      if (rechargeExclusive === 1) {
        this.$api.getExclusiveAddress().then(({ data }) => {
          this.personalAddress = data.address
        })
      }
    })
  },
  methods: {
    ...mapActions([
      'getAssetDetail',
      'getWalletAddress',
    ]),
    onCopy () {
      this.$copy(this.address || this.personalAddress)
      this.$toast(this.$t('copied'))
    },
    getQrcode (address) {
      if (!address) {
        return ''
      }
      const qrcode = new QRCode({
        content: address,
        padding: 2,
        xmlDeclaration: false,
        container: 'svg-viewbox',
        join: true,
      })
      return qrcode.svg()
    },
  },
}
</script>

<style lang="scss" scoped>
.recharge {
  .text-gray {
    color: #979ab6;
  }
}
.card {
  margin: 14px 10px;
  background-color: var(--card-bg2);
  border-radius: 10px;
  font-size: 14px;
  .label::before {
    margin-right: 8px;
    content: '';
    display: inline-block;
    width: 4px;
    height: 13px;
    background-color: currentColor;
    border-radius: 5px;
  }
}
section + section {
  margin-top: 64px;
}
.qrcode {
  margin: 8px auto;
  width: 156px;
  height: 156px;
  border-radius: 4px;
  overflow: hidden;
}
.footer {
  margin: 24px 10px 20px;
  .dui-button {
    height: 49px;
    font-size: 16px;
    border-radius: 10px;
    background-color: #3D3F73;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-copy,
    .icon-save {
      width: 21px;
      height: 21px;
    }
  }
}
</style>
