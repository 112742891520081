// token
export const APP_TOKEN = 'APP_TOKEN'

export const TOLERANCE_VALUE = 'TOLERANCE_VALUE'

export const APP_THEME = 'APP_THEME'

export const APP_LANG = 'APP_LANG'

export const REG_INPUT_NUMBER = /^[1-9]\d*\.?\d{0,18}|^0(\.\d{0,18})?|^/

export const REG_EMAIL = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
