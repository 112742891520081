<template>
  <div>
    <nav-bar>合约列表</nav-bar>
    <div class="item solid-bottom" v-for="item in list" :key="item.id">
      <div class="flex align-center">
        <div class="flex-sub margin-right upper-case">{{item.name}}</div>
        <span class="text-sm upper-case">主网：{{item.network.name}}</span>
      </div>
      <div class="flex justify-between margin-top-xs">
        <span class="text-gray text-sm">{{item.contract}}</span>
        <span class="text-sm">{{item.status_text}}</span>
      </div>
    </div>
    <div v-if="nextStatus === 'more'" v-load="onLoadMore"></div>
    <div v-else-if="nextStatus === 'noMore'" class="padding-tb-lg text-gray text-center">{{$t('noMore')}}</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState(['contract']),
    list () {
      return this.contract?.list
    },
    nextStatus () {
      return this.contract?.nextStatus
    },
  },
  mounted () {
    // 获取资产变更记录
    this.onLoadMore(true)
  },
  methods: {
    ...mapActions(['getContractList']),
    onLoadMore (isRefresh = false) {
      this.getContractList({
        cursor: isRefresh ? null : undefined,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-gray {
  color: #8e9296;
}
.item {
  margin: 0 15px;
  padding: 15px 0;
  border-color: var(--border-color);
}
body[data-dui-theme="dark"] {
  .text-gray {
    color: var(--placeholder-color);
  }
}
</style>
