<template>
  <div>
    <nav-bar>好友</nav-bar>
    <div class="card padding" v-if="inviter">
      <div class="label"><icon type="collection" class="margin-right-xs"></icon>我的邀请人</div>
      <div class="item padding-tb flex align-center">
        <div class="avatar upper-case round">{{inviter.username.substr(0, 1)}}</div>
        <div class="margin-left">
          <div class="username">
            {{inviter.username}}
            <small class="margin-left-xs">UID:{{inviter.id}}</small>
          </div>
          <div>他邀请了<span class="text-yellow">{{inviter.invitations_count}}</span>人</div>
        </div>
      </div>
    </div>
    <div class="card padding">
      <div class="label"><icon type="love" class="margin-right-xs"></icon>好友</div>
      <div class="item padding-tb flex align-center" v-for="friend in friendList" :key="friend.id">
        <div class="avatar upper-case round">{{friend.username.substr(0, 1)}}</div>
        <div class="margin-left">
          <div class="username">
            {{friend.username}}
            <small class="margin-left-xs">UID:{{friend.id}}</small>
          </div>
          <div>他邀请了<span class="text-yellow">{{friend.invitations_count}}</span>人</div>
        </div>
      </div>
      <div v-if="nextStatus === 'empty'" class="padding text-center text-gray">
        暂无好友
      </div>
    </div>
    <div v-if="nextStatus === 'more'" v-load="onLoadMore"></div>
    <div v-else-if="nextStatus === 'noMore'" class="padding-tb text-gray text-center">{{$t('noMore')}}</div>
    <!-- footer -->
    <div class="footer" v-if="shareUrl">
      <div class="fixed padding flex align-center">
        <div class="flex-sub">{{shareUrl}}</div>
        <button class="dui-button bg-blue" @click="onCopy">
          <icon type="copy"></icon>
          <span>复制邀请链接</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['profile', 'friend']),
    inviter () {
      return this.profile?.inviter
    },
    invitationCode () {
      return this.profile?.invitation_code
    },
    shareUrl () {
      return this.invitationCode ? `${location.origin}/register?invitation_code=${this.invitationCode}` : null
    },
    nextStatus () {
      return this.friend?.nextStatus
    },
    friendList () {
      return this.friend?.list ?? []
    },
  },
  created () {
    this.onLoadMore()
  },
  methods: {
    ...mapActions(['getFriendList']),
    onLoadMore (isRefresh = false) {
      this.getFriendList({
        page: isRefresh ? 1 : undefined,
      })
    },
    onCopy () {
      this.$copy(this.shareUrl)
      this.$toast('已复制')
    },
  },
}
</script>

<style lang="scss" scoped>
.text-yellow {
  color: #FFCA30;
}
.card {
  margin: 14px 10px;
  background-color: var(--card-bg2);
  border-radius: 10px;
  font-size: 13px;
  .label {
    svg {
      transform: translateY(1px);
    }
  }
  .item {
    position: relative;
    line-height: 1.8;
    & + .item::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-top: 1px solid var(--border-color);
      transform: scaleY(0.5);
    }
  }
  .avatar {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    background-color: #474981;
  }
  .username {
    font-size: 14px;
    small {
      color: #474981;
      font-size: 12px;
    }
  }
  .text-gray {
    color: var(--placeholder-color);
  }
}

.footer {
  height: 82px;
  line-height: 1.4;
  .fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--dui-BG);
  }
  .dui-button {
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 50px;
    width: 144px;
    font-size: 13px;
    .icon-copy {
      margin-right: 8px;
      width: 17px;
      height: 17px;
    }
  }
}
</style>
