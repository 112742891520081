<template>
  <div class="dui-page">
    <div class="header-bar">
      <div class="flex justify-between align-center">
        <a class="logo margin-left"></a>
        <div>
          <button class="dui-button login-button round" @click="$router.replace({name: 'Login'})">
            {{$t('goLogin')}}
            <icon type="right" class="margin-left-xs"></icon>
          </button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="title padding-left-xl">{{$t('register')}}</div>
      <input type="password" autocomplete="new-password" hidden>
      <div class="padding-lr-sm margin-top-xl">
        <div class="item">
          <div class="label">{{$t('account')}}</div>
          <div class="edit flex align-center">
            <input v-model="form.username" type="text" :placeholder="$t('placeholder.inputYour', {name: $t('account')})">
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('password')}}</div>
          <div class="edit flex align-center">
            <input v-model="form.password" type="password" :placeholder="$t('placeholder.inputYour', {name: $t('password')})" maxlength="20">
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('confirmPassword')}}</div>
          <div class="edit flex align-center">
            <input v-model="form.confirmPassword" type="password" :placeholder="$t('placeholder.inputConfirmPassword')" maxlength="20">
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('invitationCode')}}（选填）</div>
          <div class="edit flex align-center">
            <input v-model="form.invitation_code" type="text" :placeholder="$t('placeholder.inputYour', {name: $t('invitationCode')})" maxlength="10">
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('code')}}</div>
          <div class="edit flex align-center">
            <input class="flex-sub" v-model="form.code" type="text" :placeholder="$t('placeholder.input', {name: $t('code')})" maxlength="6">
            <div class="captcha" @click="getCaptchaImage">
              <img :src="captchaUrl">
            </div>
          </div>
        </div>
      </div>
      <div class="padding-lr-sm footer">
        <button class="dui-button bg-red block lg" @click="onRegister">{{$t('register')}}</button>
        <div class="margin-tb text-center">
          <a @click="onBackHome">
            {{$t('backHome')}}
            <icon class="margin-left-xxs" type="right"></icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validator from '@moohng/dan/src/validator'
import { usernameRule, passwordRule, confirmPasswordRule, codeRule } from './rule'

export default {
  data () {
    const { url, uuid } = this.$api.getCaptchaImage()
    return {
      captchaUrl: url,
      form: {
        username: '',
        password: '',
        confirmPassword: '',
        captcha_id: uuid,
        code: '',
        invitation_code: this.$route.query.invitation_code ?? '',
      },
    }
  },
  methods: {
    getCaptchaImage () {
      const { url, uuid } = this.$api.getCaptchaImage()
      this.captchaUrl = url
      this.form.captcha_id = uuid
    },
    onRegister () {
      const result = validator(this.form, {
        username: usernameRule,
        password: passwordRule,
        confirmPassword: confirmPasswordRule,
        code: codeRule,
      })
      if (result.hasError()) {
        return this.$toast(result.first())
      }
      this.$api.register(this.form).then(({ data }) => {
        this.$util.setToken(data.token)
        this.onBackHome()
      }).catch(() => {
        this.getCaptchaImage()
      })
    },
    onBackHome () {
      if (this.$route.query.invitation_code) {
        this.$router.replace({ name: 'Home' })
      } else {
        this.$router.back()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './login.scss';
</style>
